import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import './header.css'
import { IoMenuSharp, IoCloseSharp, IoChevronDownSharp } from "react-icons/io5";
import { MdQrCode } from "react-icons/md";
import { BsCalendarCheck } from "react-icons/bs";

const resourcesMenu = [
    {
        header: "Tools",
        items: [
            {
                name: "Meeting Finder Tool",
                description: "Find the perfect time to meet",
                icon: BsCalendarCheck,
                path: "/meeting-finder"
            },
            {
                name: "Survey QR Code Maker",
                description: "Create a QR code for a survey link from Google Forms or SurveyMonkey",
                icon: MdQrCode,
                path: "/qr-code-generator"
            }
        ]
    }
];

export default function Header() {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showResources, setShowResources] = useState(false);
    const [isMenuHiding, setIsMenuHiding] = useState(false);
    const menuTimeoutRef = useRef(null);
    const navigate = useNavigate();

    const handleMobileNavigation = (location) => {
        setMenuIsOpen(false);
        navigate(location);
    }

    const handleMenuEnter = () => {
        if (menuTimeoutRef.current) {
            clearTimeout(menuTimeoutRef.current);
        }
        setIsMenuHiding(false);
        setShowResources(true);
    };

    const handleMenuLeave = () => {
        setIsMenuHiding(true);
        menuTimeoutRef.current = setTimeout(() => {
            setShowResources(false);
            setIsMenuHiding(false);
        }, 150);
    };

    return (
        <div className="header" style={{backgroundColor: "#0A0A0A"}}>
            {/* DESKTOP HEADER */}
            <div className="header-desktop header-space-control header-align-left">
                <div className="header-option-unauthed-names">
                    <div className="header-option-name" onClick={() => navigate("/")}>Home</div>
                    <div className="header-option-name resources-dropdown" onMouseEnter={handleMenuEnter} onMouseLeave={handleMenuLeave}>
                        Resources <IoChevronDownSharp/>
                        {showResources && (
                            <div className={`resources-menu ${isMenuHiding ? 'hiding' : ''}`}>
                                {resourcesMenu.map((section, index) => (
                                    <div key={index} className="resources-section">
                                        <div className="resources-section-header">{section.header}</div>
                                        {section.items.map((item, itemIndex) => (
                                            <div 
                                                key={itemIndex} 
                                                className="resources-item"
                                                onClick={() => navigate(item.path)}
                                            >
                                                <div className="resources-item-icon">
                                                    <item.icon />
                                                </div>
                                                <div className="resources-item-content">
                                                    <div className="resources-item-name">{item.name}</div>
                                                    <div className="resources-item-description">{item.description}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="header-option-name" onClick={() => navigate("/plans")}>Pricing</div>
                    <div className="header-option-name" onClick={() => navigate("/blog")}>Blog</div>
                </div>
            </div>

            <div className="header-desktop header-auth-options header-option-names header-space-control header-align-center">
                <div className="header-logo" onClick={() => navigate("/")}>Tether<span>form</span></div>
            </div>

            <div className="header-desktop header-auth-options header-space-control header-align-right">
                <span onClick={() => navigate("/register")} className="header-auth-option-login">Sign Up</span>
                <span onClick={() => navigate("/login")}>Login</span>
            </div>

            {/* MOBILE HEADER */}
            <div className="header-mobile-outer">
                <div className="header-mobile">
                    <div className="header-logo" onClick={() => navigate("/")}>Tether<span>form</span></div>
                    {!menuIsOpen && <div className="header-mobile-menu-icon" onClick={() => setMenuIsOpen(true)}><IoMenuSharp/></div>}
                    {menuIsOpen && <div className="header-mobile-menu-icon" onClick={() => setMenuIsOpen(false)}><IoCloseSharp/></div>}
                </div>
            </div>
            {menuIsOpen &&
            <div className="header-mobile-menu">
                <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/login")}>Login</div>
                <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/plans")}>Pricing</div>
                <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/register")}>Sign up</div>
                <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/blog")}>Blog</div>
                <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/meeting-finder")}>Meeting Finder</div>
            </div>
            }
        </div>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import './FormItemMenuPane.css';
import FormBuilderToolSet from '../canvas/form_toolset/FormBuilderToolSet';
import FormBuilderOutline from '../canvas/form_outline/FormBuilderOutline';
import { RiPagesFill } from "react-icons/ri";
import { AiFillPlusSquare } from "react-icons/ai";

const MIN_WIDTH = 200;
const MAX_WIDTH = 480;

export default function FormItemMenuPane({ onSelectItem, questions, onEditQuestion }) {
    const [activeView, setActiveView] = useState('outline');
    const [width, setWidth] = useState(320);
    const [isResizing, setIsResizing] = useState(false);
    const resizeRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;

            // Calculate new width based on mouse position
            const newWidth = e.clientX;
            
            // Constrain width within limits
            const constrainedWidth = Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, newWidth));
            
            setWidth(constrainedWidth);
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    const handleResizeStart = (e) => {
        e.preventDefault();
        setIsResizing(true);
    };

    return (
        <div 
            className="form-item-menu-pane"
            style={{ width: `${width}px` }}
        >
            <div className="form-item-menu-header">
                <div 
                    className={`form-item-menu-tab ${activeView === 'outline' ? 'active' : ''}`}
                    onClick={() => setActiveView('outline')}
                    title="Form Outline"
                >
                    <RiPagesFill />
                </div>
                <div 
                    className={`form-item-menu-tab ${activeView === 'toolset' ? 'active' : ''}`}
                    onClick={() => setActiveView('toolset')}
                    title="Add Form Elements"
                >
                    <AiFillPlusSquare />
                </div>
            </div>
            {activeView === 'toolset' ? (
                <FormBuilderToolSet onSelectItem={onSelectItem} />
            ) : (
                <FormBuilderOutline 
                    questions={questions} 
                    onEditQuestion={onEditQuestion}
                />
            )}
            <div 
                ref={resizeRef}
                className={`form-item-menu-resize-handle ${isResizing ? 'active' : ''}`}
                onMouseDown={handleResizeStart}
            />
        </div>
    );
}
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Home from './components/home/Home';
import Landing from './components/landing/Landing';
import Register from './components/user_management/register/Register';
import PasswordReset from './components/user_management/reset/PasswordReset';
import ForgotPassword from './components/user_management/reset/ForgotPassword';
import Login from './components/user_management/login/Login';
import DashboardSplash from './components/dashboard/DashboardSplash';
import OrgSettings from './components/orgsettings/OrgSettings'
import OrganizationUsers from './components/organization_users/OrganizationUsers';
import OrgForms from './components/forms_list/OrgForms';
import OrgImports from './components/imports/OrgImports';
import FormBuilder from './components/form/FormBuilder';
import Dashboard from './components/dashboard/Dashboard';
import PricingPage from './components/home/PricingPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import TheFormPreview from './components/public/TheFormPreview';
import FormWrapper from './components/public/FormWrapper';
import SetupAccount from './components/account_setup/SetupAccount';
import QrCodeGenerator from './components/tools/qr_code_generator/QrCodeGenerator';
import MeetingFinderLanding from './components/tools/meeting_finder/MeetingFinderLanding';
import MeetingFinderCreator from './components/tools/meeting_finder/MeetingFinderCreator';
import MeetingFinderJoin from './components/tools/meeting_finder/MeetingFinderJoin';
import MeetingFinderManager from './components/tools/meeting_finder/MeetingFinderManager';
import BlogLanding from './components/blog/BlogLanding';
import BlogPost from './components/blog/BlogPost';
import AboutUs from './components/company/AboutUs';
import ComparisonPage from './components/comparison/ComparisonPage';
import AuthenticatedSignUpPage from './components/account_setup/AuthenticatedSignUpPage';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';
import './App.css';
import { OrganizationProvider } from './context/OrganizationContext';
import CreateNewForm from './components/forms_list/CreateNewForm';
import ImportWizard from './components/imports/import/ImportWizard';
import ImportDetails from './components/imports/import/ImportDetails';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <OrganizationProvider>
          <div className="app">
            <ErrorBoundary>
              <Routes>
                {/* Public routes with header */}
                <Route element={<UnauthenticatedLayout />}>
                  {/* <Route path="/" element={<Home />} /> */}
                  <Route path="/" element={<Landing />} />
                  {/* Auth-only public routes */}
                  <Route path="/register" element={<AuthOnlyPublicRoute><Register /></AuthOnlyPublicRoute>} />
                  <Route path="/login" element={<AuthOnlyPublicRoute><Login /></AuthOnlyPublicRoute>} />
                  <Route path="/reset-password" element={<AuthOnlyPublicRoute><PasswordReset /></AuthOnlyPublicRoute>} />
                  <Route path="/forgot-password" element={<AuthOnlyPublicRoute><ForgotPassword /></AuthOnlyPublicRoute>} />
                  
                  {/* Shared public routes - accessible by both authenticated and unauthenticated users */}
                  <Route path="/plans" element={<PricingPage />} />
                  <Route path="/blog" element={<BlogLanding />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/compare/:competitorName" element={<ComparisonPage />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/meeting-finder" element={<MeetingFinderLanding />} />
                  <Route path="/meeting-finder/create" element={<MeetingFinderCreator />} />
                  <Route path="/meeting-finder/polls/:pollId" element={<MeetingFinderJoin />} />
                  <Route path="/qr-code-generator" element={<QrCodeGenerator />} />
                </Route>

                {/* Routes without layout */}
                <Route path="/f/:formId" element={<FormWrapper />} />
                <Route path="/pr/:organizationId/:formId" element={<TheFormPreview />} />
                <Route path="/meeting-finder/manage/:pollId" element={<MeetingFinderManager />} />
                
                {/* Private routes with authenticated layout */}
                <Route path="/welcome" element={<PrivateRoute><DashboardSplash /></PrivateRoute>} />
                <Route path="/account-setup" element={<PrivateRoute><SetupAccount /></PrivateRoute>} />
                
                {/* Organization routes with authenticated layout */}
                <Route path="/o/:organizationId" element={
                  <PrivateRouteRequiringActiveOrganization>
                    <AuthenticatedLayout>
                      <Outlet />
                    </AuthenticatedLayout>
                  </PrivateRouteRequiringActiveOrganization>
                }>
                  <Route path="" element={<Dashboard />} />
                  <Route path="forms" element={<OrgForms />} />
                  <Route path="forms/create" element={<CreateNewForm />} />
                  <Route path="forms/:formId" element={<FormBuilder />} />
                  <Route path="settings" element={<OrgSettings />} />
                  <Route path="rejoin" element={<AuthenticatedSignUpPage />} />
                  <Route path="users" element={<OrganizationUsers />} />
                  <Route path="imports" element={<OrgImports />} />
                  <Route path="imports/new" element={<ImportWizard />} />
                  <Route path="imports/:importId" element={<ImportDetails />} />
                </Route>
                
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </OrganizationProvider>
      </Router>
    </AuthProvider>
  );
}

function PrivateRoute({ children }) {
  // eslint-disable-next-line
  const { isAuthenticated, loading, isActiveAccount } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

function PrivateRouteRequiringActiveOrganization({ children }) {
  const { isAuthenticated, loading, isActiveAccount } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  if (isActiveAccount && isAuthenticated) return children
  if (!isActiveAccount && isAuthenticated) return <Navigate to="/account-setup" />

  return <Navigate to="/login" />;
}

function AuthOnlyPublicRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return isAuthenticated ? <Navigate to="/welcome" /> : children;
}
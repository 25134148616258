import React, { useState, useRef, useEffect } from 'react'
import './QuestionCanvas.css'
import { FaPlus } from 'react-icons/fa'
import { MdOpenInNew } from "react-icons/md";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import AddQuestionFloatingModal from './AddQuestionFloatingModal'
import ModalQuestionManage from './ModalQuestionManage'
import ModalDeleteQuestion from './ModalDeleteQuestion'
import AddQuestionButton from './canvas_misc/AddQuestionButton'
import FormCanvasItem from './canvas_misc/FormCanvasItem'
import FormItemMenuPane from '../menu/FormItemMenuPane'
import { v4 as uuidv4 } from 'uuid';

const QuestionConnector = ({ onClick }) => (
    <div className="question-canvas-connector">
        <div 
            className="question-canvas-add-button"
            onClick={onClick}
        >
            <FaPlus />
        </div>
    </div>
);

export default function QuestionCanvas({ 
    questions, 
    onQuestionsChange, 
    onAddQuestion, 
    onUpdateQuestion, 
    onDeleteQuestion,
    openPreview
}) {
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [addQuestionPosition, setAddQuestionPosition] = useState({ x: 0, y: 0 });
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const addQuestionRef = useRef(null);
    const [showQuestionManageModal, setShowQuestionManageModal] = useState(false);
    const [selectedQuestionType, setSelectedQuestionType] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [insertIndex, setInsertIndex] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [newQuestionId, setNewQuestionId] = useState(null);

    const handleElementClick = (event, element) => {
        handleEditElement(element);
    };

    const handleAddElementClick = (event, index) => {
        const rect = containerRef.current.getBoundingClientRect();
        const scrollTop = containerRef.current.scrollTop;
        setAddQuestionPosition({ 
            x: event.clientX, 
            y: event.clientY - rect.top + scrollTop
        });
        setShowAddQuestionModal(true);
        setInsertIndex(index + 1);
        setEditingQuestion(null);
    };

    const handleSelectQuestionType = (questionType) => {
        setSelectedQuestionType(questionType);
        setShowAddQuestionModal(false);
        setShowQuestionManageModal(true);
    };

    const handleClickOutside = (event) => {
        if (addQuestionRef.current && !addQuestionRef.current.contains(event.target)) {
            setShowAddQuestionModal(false);
        }
    };

    const handleSaveElement = (elementData) => {
        if (editingQuestion) {
            const updatedElements = questions.map(q => 
                q.id === editingQuestion.id ? elementData : q
            );
            onQuestionsChange(updatedElements);
        } else {
            onAddQuestion(elementData, insertIndex);
        }
        setShowQuestionManageModal(false);
        setEditingQuestion(null);
        setInsertIndex(null);
    };

    const handleEditElement = (element) => {
        setEditingQuestion(element);
        setSelectedQuestionType(element.type);
        setShowQuestionManageModal(true);
    };

    const handleMenuItemClick = (questionType) => {
        setSelectedQuestionType(questionType);
        setShowQuestionManageModal(true);
        setEditingQuestion(null);
        setInsertIndex(questions.length);
    };

    const handleDeleteIconClick = (event, element) => {
        event.stopPropagation();
        setQuestionToDelete(element);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        if (questionToDelete) {
            onDeleteQuestion(questionToDelete.id);
            setShowDeleteModal(false);
            setQuestionToDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setQuestionToDelete(null);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;

        if (source.index !== destination.index) {
            const newElements = Array.from(questions);
            const [reorderedItem] = newElements.splice(source.index, 1);
            newElements.splice(destination.index, 0, reorderedItem);

            onQuestionsChange(newElements);
        }
    };

    const handleDuplicateElement = (event, element, index) => {
        event.stopPropagation();
        
        const duplicatedElement = {
            ...element,
            id: uuidv4()
        };

        const newElements = [...questions];
        newElements.splice(index + 1, 0, duplicatedElement);
        
        onQuestionsChange(newElements);
        
        setNewQuestionId(duplicatedElement.id);
        
        setTimeout(() => {
            setNewQuestionId(null);
        }, 200);
    };

    useEffect(() => {
        if (showAddQuestionModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAddQuestionModal]);

    // Calculate question numbers (excluding section headings)
    const getQuestionNumber = (index) => {
        let questionCount = 0;
        for (let i = 0; i <= index; i++) {
            if (questions[i].type !== 'section-heading') {
                questionCount++;
            }
        }
        return questionCount;
    };

    return (
        <div className="question-canvas-layout">
            <FormItemMenuPane 
                onSelectItem={handleMenuItemClick}
                selectedType={selectedQuestionType}
                questions={questions}
                onEditQuestion={handleEditElement}
            />
            <div className="question-canvas-container" ref={containerRef}>
                {questions.length > 0 &&
                <div className="question-canvas-container-preview-buttons" onClick={openPreview}>
                    Open preview
                    <MdOpenInNew/>
                </div>}
                <div className="question-canvas-scroll-container">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="questions">
                            {(provided) => (
                                <div 
                                    className="question-canvas-content" 
                                    ref={(el) => {
                                        contentRef.current = el;
                                        provided.innerRef(el);
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {questions.length === 0 ? (
                                        <AddQuestionButton onClick={(e) => handleAddElementClick(e, -1)} />
                                    ) : (
                                        questions.map((element, index) => (
                                            <React.Fragment key={element.id}>
                                                <FormCanvasItem
                                                    element={element}
                                                    index={index}
                                                    onElementClick={handleElementClick}
                                                    onDeleteIconClick={handleDeleteIconClick}
                                                    onDuplicateIconClick={handleDuplicateElement}
                                                    isNew={element.id === newQuestionId}
                                                    questionNumber={getQuestionNumber(index)}
                                                />
                                                {index < questions.length - 1 && (
                                                    <QuestionConnector onClick={(e) => handleAddElementClick(e, index)} />
                                                )}
                                            </React.Fragment>
                                        ))
                                    )}
                                    {provided.placeholder}
                                    {questions.length > 0 && (
                                        <>
                                            <QuestionConnector onClick={(e) => handleAddElementClick(e, questions.length - 1)} />
                                            <div className="question-canvas-end">
                                                <span>End</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            {showAddQuestionModal && (
                <div ref={addQuestionRef}>
                    <AddQuestionFloatingModal 
                        onSelectQuestionType={handleSelectQuestionType}
                        style={{
                            position: 'absolute',
                            left: `${addQuestionPosition.x}px`,
                            top: `${addQuestionPosition.y}px`,
                            transform: 'translate(-50%, 0)',
                        }}
                    />
                </div>
            )}
            {showQuestionManageModal && (
                <ModalQuestionManage
                    onClose={() => setShowQuestionManageModal(false)}
                    questionType={selectedQuestionType}
                    existingQuestion={editingQuestion}
                    onSave={handleSaveElement}
                />
            )}
            {showDeleteModal && (
                <ModalDeleteQuestion
                    onCancel={handleDeleteCancel}
                    onConfirm={handleDeleteConfirm}
                />
            )}
        </div>
    );
}
import React, { useState } from 'react';
import { 
    FaList, 
    FaStar, 
    FaCheckSquare, 
    FaFont, 
    FaHeading,
    FaThumbsUp
} from 'react-icons/fa';
import './FormBuilderOutline.css';

const questionTypeIcons = {
    'text': FaFont,
    'multiple-choice': FaList,
    'select-multiple': FaCheckSquare,
    'rating': FaStar,
    'section-heading': FaHeading
};

const getQuestionIcon = (type, scale) => {
    if (type === 'rating' && scale?.symbol === 'thumbsUp') {
        return FaThumbsUp;
    }
    return questionTypeIcons[type] || FaFont;
};

export default function FormBuilderOutline({ questions, onEditQuestion }) {
    const [hoveredQuestion, setHoveredQuestion] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (question, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = rect.right + 8; // 8px offset from the item
        let y = rect.top;

        // Check if tooltip would go off bottom of screen
        const tooltipHeight = 200; // Approximate height of tooltip
        if (y + tooltipHeight > window.innerHeight) {
            y = window.innerHeight - tooltipHeight - 8; // 8px padding from bottom
        }

        // Check if tooltip would go off top of screen
        if (y < 8) {
            y = 8; // 8px padding from top
        }

        setTooltipPosition({ x, y });
        setHoveredQuestion(question);
    };

    const handleMouseLeave = () => {
        setHoveredQuestion(null);
    };

    const handleClick = (question, event) => {
        // Only trigger edit if we're clicking the item itself, not the tooltip
        if (event.target.closest('.form-builder-outline-tooltip')) {
            return;
        }
        onEditQuestion(question);
    };

    if (!questions || !Array.isArray(questions) || questions.length === 0) {
        return (
            <div className="form-builder-outline-empty">
                No questions added yet
            </div>
        );
    }

    return (
        <div className="form-builder-outline-container">
            <div className="form-builder-outline-heading">Outline</div>
            {questions.map((question) => {
                const Icon = getQuestionIcon(question.type, question.scale);
                return (
                    <div 
                        key={question.id}
                        className="form-builder-outline-item"
                        onMouseEnter={(e) => handleMouseEnter(question, e)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(e) => handleClick(question, e)}
                    >
                        <Icon className="form-builder-outline-item-icon" />
                        <span className="form-builder-outline-item-text">
                            {question.question}
                        </span>
                    </div>
                );
            })}
            {hoveredQuestion && (
                <div 
                    className="form-builder-outline-tooltip"
                    style={{
                        left: tooltipPosition.x,
                        top: tooltipPosition.y
                    }}
                >
                    <h4>{hoveredQuestion.question}</h4>
                    {hoveredQuestion.description && (
                        <p className="form-builder-outline-tooltip-description">
                            {hoveredQuestion.description}
                        </p>
                    )}
                    <div className="form-builder-outline-tooltip-details">
                        <span>Type: {hoveredQuestion.type}</span>
                        <span>Required: {hoveredQuestion.required ? 'Yes' : 'No'}</span>
                        {hoveredQuestion.type === 'rating' && (
                            <>
                                <span>Scale: {hoveredQuestion.scale.min} - {hoveredQuestion.scale.max}</span>
                                <span>Labels: {hoveredQuestion.scale.minLabel} - {hoveredQuestion.scale.maxLabel}</span>
                            </>
                        )}
                        {(hoveredQuestion.type === 'multiple-choice' || hoveredQuestion.type === 'select-multiple') && (
                            <div className="form-builder-outline-tooltip-options">
                                <span>Options:</span>
                                <ul>
                                    {hoveredQuestion.options.options.map((option, index) => (
                                        <li key={index}>{option}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {hoveredQuestion.type === 'text' && (
                            <span>Max Length: {hoveredQuestion.textOptions.maxLength}</span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

import React, { useState, useRef, useEffect, useCallback } from 'react'
import './OrgForms.css'
import { useOrganization } from '../../context/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../loaders/Loader';
import { FaSearch } from 'react-icons/fa';
import { FaPlus } from "react-icons/fa6";
import { TbArrowsDownUp } from "react-icons/tb";
import { IoDocumentText } from 'react-icons/io5';

import { HiOutlineDotsHorizontal } from "react-icons/hi";
import ModalOrgDeleteForm from './ModalOrgDeleteForm';
import { duplicateForm, updateFormDraft } from '../../api';
import { formatDistanceToNow } from 'date-fns';

const sortOptions = [
    { value: 'last_modified', label: 'Last modified' },
    { value: 'created', label: 'Created' },
    { value: 'title', label: 'Title (A-Z)' },
    { value: 'responses', label: 'Response count' },
    { value: 'status', label: 'Status' }
];

export default function OrgForms() {
    const navigate = useNavigate()
    const { organization, organizationIsLoading, forms, fetchOrganizationDetails, isActiveLicense } = useOrganization()
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formToDelete, setFormToDelete] = useState(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, formId: null });
    const contextMenuRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('last_modified');
    const [showSortMenu, setShowSortMenu] = useState(false);
    const sortMenuRef = useRef(null);

    const handleClickOutside = useCallback((e) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
            setContextMenu(prev => ({ ...prev, visible: false }));
        }
        if (sortMenuRef.current && !sortMenuRef.current.contains(e.target)) {
            setShowSortMenu(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const sortForms = (forms) => {
        return [...forms].sort((a, b) => {
            switch (sortBy) {
                case 'last_modified':
                    return new Date(b.updatedAt) - new Date(a.updatedAt);
                case 'created':
                    return new Date(b.createdAt) - new Date(a.createdAt);
                case 'title':
                    return a.title.localeCompare(b.title);
                case 'responses':
                    return (b.response_count || 0) - (a.response_count || 0);
                case 'status':
                    const statusOrder = { active: 1, draft: 2, closed: 3 };
                    const aStatus = a.schedule_end && new Date() > new Date(a.schedule_end) ? 'closed' : a.status;
                    const bStatus = b.schedule_end && new Date() > new Date(b.schedule_end) ? 'closed' : b.status;
                    return statusOrder[aStatus] - statusOrder[bStatus];
                default:
                    return 0;
            }
        });
    };

    const filteredAndSortedForms = sortForms(
        forms.filter(form => !searchQuery || form.title.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleNewForm = () => {
        navigate(`/o/${organization._id}/forms/create`);
    };

    const handleMenuClick = (e, formId) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setContextMenu({ 
            visible: true, 
            x: rect.left, 
            y: rect.bottom + window.scrollY, 
            formId 
        });
    };

    const openPreview = (e, formId) => {
        e.stopPropagation();
        const newWindow = window.open(`/pr/${organization._id}/${formId}`, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleEditClick = (e, formId) => {
        e.stopPropagation();
        navigate(`/o/${organization._id}/forms/${formId}`);
    };

    const handleDuplicateForm = async () => {
        try {
            await duplicateForm(contextMenu.formId);
            await fetchOrganizationDetails(organization._id);
            setContextMenu(prev => ({ ...prev, visible: false }));
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    };

    const handleDeleteForm = async () => {
        const formToDelete = forms.find(form => form._id === contextMenu.formId);
        if (formToDelete) {
            setFormToDelete(formToDelete);
            setShowDeleteForm(true);
        }
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const confirmDeleteForm = async () => {
        setIsDeleting(true);
        try {
            await updateFormDraft(formToDelete._id, { status: 'deleted' });
            await fetchOrganizationDetails(organization._id);
            setShowDeleteForm(false);
        } catch (error) {
            console.error('Error deleting form:', error);
        } finally {
            setIsDeleting(false);
        }
    };
    useEffect(() => {
        document.title = "Forms | Tetherform";
    }, [])
    return (
        <div className="org-forms-container">
            {organization && !organizationIsLoading &&
            <div className="organization-forms">
                {isActiveLicense && forms.length > 0 &&
                <div className="org-forms-main">
                    <div className="org-forms-header">
                        <div className="org-forms-header-titles">
                            <div className="org-forms-header-titles-page-title">
                                <IoDocumentText/>
                                <h2>Forms</h2>
                            </div>
                            <button className="org-forms-new-button" onClick={handleNewForm}>
                                <FaPlus className="org-forms-icon" />
                                <span className="org-forms-new-button-text">New Form</span>
                            </button>
                        </div>
                        <div className="org-forms-filters">
                            <div className="org-forms-search">
                                <FaSearch className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search forms..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="org-forms-search-input"
                                />
                            </div>
                            <div className="org-forms-sort" ref={sortMenuRef}>
                                <button 
                                    className="org-forms-sort-button" 
                                    onClick={() => setShowSortMenu(!showSortMenu)}
                                >
                                    <TbArrowsDownUp className="sort-icon" />
                                    <span>Sort: {sortOptions.find(opt => opt.value === sortBy)?.label}</span>
                                </button>
                                {showSortMenu && (
                                    <div className="org-forms-sort-menu">
                                        {sortOptions.map((option) => (
                                            <button
                                                key={option.value}
                                                className={`org-forms-sort-option ${sortBy === option.value ? 'active' : ''}`}
                                                onClick={() => {
                                                    setSortBy(option.value);
                                                    setShowSortMenu(false);
                                                }}
                                            >
                                                {option.label}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="org-forms-list">
                        {filteredAndSortedForms.map((form) => {
                            const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
                            const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
                            
                            return (
                                <div 
                                    key={form._id} 
                                    className="org-forms-item"
                                >                                    
                                    <div className="org-forms-item-header">
                                        <div className="org-forms-item-title-section">
                                            <span className={`form-status badge-${status.toLowerCase()}`}>{status}</span>
                                            <h3 className="form-title">{form.title}</h3>
                                            <div className="form-subtitle">{form.introduction_message ? form.introduction_message : "No description"}</div>
                                        </div>
                                    </div>
                                    <span className="org-forms-updated-date">
                                        {formatDistanceToNow(new Date(form.updatedAt), { addSuffix: true })}
                                    </span>

                                    {form.response_count > 0 && (
                                        <div className="org-forms-item-stats">
                                            <div className="org-forms-response-badge">
                                                {form.response_count} {form.response_count === 1 ? 'response' : 'responses'}
                                            </div>
                                        </div>
                                    )}

                                    <div className="org-forms-item-actions">
                                        <button 
                                            className="org-forms-action-button menu-button"
                                            onClick={(e) => handleMenuClick(e, form._id)}
                                        >
                                            <HiOutlineDotsHorizontal />
                                        </button>
                                        <div className="org-forms-action-buttons-right">
                                            {(status === 'Live' || status === 'Draft') && (
                                                <button 
                                                    className="org-forms-action-button"
                                                    onClick={(e) => openPreview(e, form._id)}
                                                >
                                                    Preview
                                                </button>
                                            )}
                                            <button 
                                                className="org-forms-action-button org-forms-action-button-edit"
                                                onClick={(e) => handleEditClick(e, form._id)}
                                            >
                                                Manage
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                }
                {isActiveLicense && forms.length === 0 &&
                <div className="org-forms-main">
                    <div className="org-forms-empty-state">
                        <div className="org-forms-empty-state-content">
                            <h1>Create your first form</h1>
                            <p>Start collecting responses by creating your first form. It only takes a few minutes to get started.</p>
                            
                            <div className="org-forms-empty-state-steps">
                                <div className="org-forms-empty-step">
                                    <div className="org-forms-empty-step-number">1</div>
                                    <div className="org-forms-empty-step-content">
                                        <h3>Name your form</h3>
                                        <p>Give your form a name that describes its purpose</p>
                                    </div>
                                </div>
                                <div className="org-forms-empty-step">
                                    <div className="org-forms-empty-step-number">2</div>
                                    <div className="org-forms-empty-step-content">
                                        <h3>Add your questions</h3>
                                        <p>Choose from multiple question types to collect the data you need</p>
                                    </div>
                                </div>
                                <div className="org-forms-empty-step">
                                    <div className="org-forms-empty-step-number">3</div>
                                    <div className="org-forms-empty-step-content">
                                        <h3>Share with respondents</h3>
                                        <p>Share your form via link or embed it on your website</p>
                                    </div>
                                </div>
                            </div>

                            <button 
                                className="org-forms-empty-state-button" 
                                onClick={handleNewForm}
                            >
                                <FaPlus className="org-forms-icon" />
                                Create Your First Form
                            </button>
                        </div>
                    </div>
                </div>
                }
                {!isActiveLicense &&
                <div className="org-forms-main">
                    <div className="org-forms-no-license">
                        <div>Sign up for a plan to create and view forms</div>
                        <div className="org-forms-new-button" onClick={() => navigate(`/o/${organization._id}/rejoin`)}>View available plans</div>
                    </div>
                </div>
                }
            </div>
            }
            {organizationIsLoading &&
            <div className="organization-forms" style={{marginTop: "20vh"}}>
                <Loader/>
            </div>
            }
            {contextMenu.visible && (
                <div 
                    ref={contextMenuRef}
                    className="context-menu"
                    style={{ position: 'fixed', top: contextMenu.y, left: contextMenu.x }}
                >
                    <button onClick={(e) => handleEditClick(e, contextMenu.formId)}>Edit form</button>
                    <button onClick={handleDuplicateForm}>Duplicate form</button>
                    <button onClick={handleDeleteForm}>Delete form</button>
                </div>
            )}
            {showDeleteForm && (
                <ModalOrgDeleteForm
                    closeModal={() => setShowDeleteForm(false)}
                    confirmDelete={confirmDeleteForm}
                    formTitle={formToDelete?.title}
                    isDeleting={isDeleting}
                />
            )}
        </div>
    )
}

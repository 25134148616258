import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../api';

// import { Buffer } from 'buffer';
const CryptoJS = require('crypto-js')

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isActiveAccount, setIsActiveAccount] = useState(false);

  const checkAuthStatus = useCallback(async () => {
    try {
      const response = await api.get('/auth/status');
      // Assumes only one active organization for now
      if (response.data.organizations) {
        if (Array.isArray(response.data.organizations)) {
          if (response.data.organizations.length > 0) {
            setIsActiveAccount(true)
          }
        }
      }
      if (response.data.user) {
        setUser(response.data.user);
        setIsAuthenticated(true);
        setOrganizations(response.data.organizations)
      } else {
        setUser(null);
        setOrganizations([])
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      setUser(null);
      setOrganizations([])
      setIsAuthenticated(false)
      setIsActiveAccount(false)
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (email, password) => {
    try {
      const b64Pw = btoa(password);
      const maskedPassword = CryptoJS.AES.encrypt(b64Pw, process.env.REACT_APP_CRYPTO).toString()
      const response = await api.post('/auth/login', { email: email.toLowerCase().trim(), password: maskedPassword });
      return response.data.success;
    } catch (error) {
      console.error('Login error:', error.response?.data?.message || error.message);
      return false
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout');
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout error:', error);
      setUser(null);
      setIsAuthenticated(false);
      throw error;
    }
  };

  const value = {
    user,
    organizations,
    login,
    logout,
    checkAuthStatus,
    loading,
    isAuthenticated,
    isActiveAccount
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
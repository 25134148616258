import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { 
    IoDocumentTextOutline, 
    IoBusinessOutline,
    IoCalendarOutline,
    IoSearchOutline,
    IoCloseOutline,
    IoFilterOutline
} from "react-icons/io5";
import { getFormTemplates, createFormFromTemplate, createNewForm } from '../../api';
import { useOrganization } from '../../context/OrganizationContext';
import ModalOrgNewForm from './ModalOrgNewForm';
import Loader from '../loaders/Loader';
import './CreateNewForm.css';

const CATEGORIES = [
    { id: 'all', name: 'All Templates', icon: IoDocumentTextOutline },
    { id: 'associations', name: 'Associations', icon: IoBusinessOutline },
    { id: 'events', name: 'Event Planning', icon: IoCalendarOutline },
];

export default function CreateNewForm() {
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const { organization, isActiveLicense, loading } = useOrganization();
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await getFormTemplates();
                if (response.data?.templates) {
                    setTemplates(response.data.templates);
                    setFilteredTemplates(response.data.templates);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
                navigate(`/o/${organizationId}/forms`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTemplates();
    }, [organizationId, navigate]);

    useEffect(() => {
        const filterTemplates = () => {
            let filtered = [...templates];
            
            // Filter by category if not "all"
            if (selectedCategory !== 'all') {
                filtered = filtered.filter(template => {
                    console.log(template.template_categories)
                    return template.template_categories?.includes(selectedCategory)
                }

                );
            }
            
            // Filter by search term
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                filtered = filtered.filter(template => 
                    template.template_name.toLowerCase().includes(searchLower) ||
                    template.template_teaser.toLowerCase().includes(searchLower) ||
                    template.template_instructions?.toLowerCase().includes(searchLower)
                );
            }
            
            setFilteredTemplates(filtered);
        };

        filterTemplates();
    }, [templates, selectedCategory, searchTerm]);

    const handleSearchClear = () => {
        setSearchTerm('');
    };

    const handleTemplateSelect = async (templateId) => {
        setIsLoading(true);
        try {
            const response = await createFormFromTemplate(organizationId, templateId);
            if (response.data?.form?._id) {
                navigate(`/o/${organizationId}/forms/${response.data.form._id}`);
            } else {
                navigate(`/o/${organizationId}/forms`);
            }
        } catch (error) {
            console.error('Error creating form from template:', error);
            navigate(`/o/${organizationId}/forms`);
        }
    };

    const handleCreateFromScratch = (formName) => {
        createNewForm(organizationId, { title: formName, status: "draft", structure: [] })
            .then((res) => {
                if (res.data?.form?._id) {
                    navigate(`/o/${organizationId}/forms/${res.data.form._id}`);
                } else {
                    navigate(`/o/${organizationId}/forms`);
                }
            })
            .catch(() => {
                navigate(`/o/${organizationId}/forms`);
            });
    };

    if (isLoading) {
        return <div className="create-new-form-loader"><Loader /></div>;
    }
    if (!loading && !isActiveLicense) {
        return navigate("/")
    }

    return (
        <div className="create-new-form">
            <div className="create-new-form-header">
                <button 
                    className="create-new-form-back" 
                    onClick={() => navigate(`/o/${organizationId}/forms`)}
                >
                    <IoArrowBack />
                    {organization?.name || 'Organization'}
                </button>
                <button 
                    className="create-new-form-scratch-button"
                    onClick={() => setShowCreateModal(true)}
                >
                    Start from scratch
                </button>
            </div>

            <div className="create-new-form-main">
                <div className="create-new-form-content">
                    <div className="create-new-form-content-header">
                        <div className="create-new-form-content-header-left">
                            <h1 className="create-new-form-title">Form Templates</h1>
                            <div className="create-new-form-controls">
                                <div className="create-new-form-filter">
                                    <button 
                                        className="create-new-form-filter-button"
                                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                                    >
                                        <IoFilterOutline />
                                        {CATEGORIES.find(cat => cat.id === selectedCategory)?.name || 'All Templates'}
                                    </button>
                                    {isFilterOpen && (
                                        <div className="create-new-form-filter-dropdown">
                                            {CATEGORIES.map((category) => (
                                                <div 
                                                    key={category.id}
                                                    className={`create-new-form-category ${selectedCategory === category.id ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setSelectedCategory(category.id);
                                                        setIsFilterOpen(false);
                                                    }}
                                                >
                                                    <span className="create-new-form-category-icon">
                                                        <category.icon />
                                                    </span>
                                                    {category.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="create-new-form-search">
                                    <IoSearchOutline className="create-new-form-search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search templates..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    {searchTerm && (
                                        <button 
                                            className="create-new-form-search-clear"
                                            onClick={handleSearchClear}
                                        >
                                            <IoCloseOutline />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {filteredTemplates.length === 0 ? (
                        <div className="create-new-form-no-results">
                            <p>No templates found</p>
                            {searchTerm && (
                                <button 
                                    className="create-new-form-reset-search"
                                    onClick={handleSearchClear}
                                >
                                    Clear search
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className="create-new-form-templates">
                            {filteredTemplates.map((template) => (
                                <div 
                                    key={template.template_id}
                                    className="create-new-form-template"
                                    onClick={() => handleTemplateSelect(template.template_id)}
                                >
                                    <h3>{template.template_name}</h3>
                                    <p>{template.template_teaser}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {showCreateModal && (
                <ModalOrgNewForm 
                    createNewForm={handleCreateFromScratch}
                    closeForm={() => setShowCreateModal(false)}
                />
            )}
        </div>
    );
}

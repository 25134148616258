import React, { useState } from 'react';
import './FormBuilderOptions.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line
import { IoCheckmarkCircle } from "react-icons/io5";
import CheckboxSettings from '../gizmos/CheckboxSettings';
import { broadcastAnalytics } from '../../api';

const FormBuilderNotifications = ({ notificationState, onNotificationsUpdate, analytics, formId }) => {
    const [emailInput, setEmailInput] = useState('');
    const [isSending, setIsSending] = useState(false);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const handleEmailInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addEmail();
        }
    };

    const addEmail = () => {
        const trimmedEmail = emailInput.trim();
        if (validateEmail(trimmedEmail) && notificationState.notification_emails.length < 10) {
            onNotificationsUpdate({ notification_emails: [...notificationState.notification_emails, trimmedEmail] });
            setEmailInput('');
        }
    };

    const removeEmail = (email) => {
        onNotificationsUpdate({ notification_emails: notificationState.notification_emails.filter(e => e !== email) });
    };

    const setNotifyOnResponseValue = (val) => {
        onNotificationsUpdate({ "notify_on_response": val })
    }
    // eslint-disable-next-line
    const setNotifyOnScheduleEnd = (val) => {
        onNotificationsUpdate({ "notify_on_schedule_end": val })
    }

    const setNotifyOnDate = (val) => {
        onNotificationsUpdate({ "notify_on_date": val })
    }

    const handleDateChange = (date) => {
        onNotificationsUpdate({ 
            notify_on_date: !!date,
            notification_date: date 
        });
    };
// eslint-disable-next-line
    const handleIncludeChange = (item) => {
        const newIncludes = notificationState.notification_includes.includes(item)
            ? notificationState.notification_includes.filter(i => i !== item)
            : [...notificationState.notification_includes, item];
        onNotificationsUpdate({ notification_includes: newIncludes });
    };

    const handleBroadcastAnalytics = async () => {
        if (isSending) return;
        
        setIsSending(true);
        try {
            await broadcastAnalytics(formId);
        } catch (error) {
            console.error('Error broadcasting analytics:', error);
        }
        
        // Reset after 4 seconds
        setTimeout(() => {
            setIsSending(false);
        }, 4000);
    };

    return (
        <div className="form-builder-notifications">
            <div className="form-builder-design-options-header-row"><h2>Notifications</h2></div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Analytics</h4>
                    <p>Key takeaways and response analysis</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="checkbox-group">
                        {/* <label>
                            <CheckboxSettings isSelected={notificationState.notify_on_schedule_end} setIsSelected={setNotifyOnScheduleEnd} />
                            When the form is closed
                        </label> */}
                        <label>
                            <CheckboxSettings 
                                isSelected={notificationState.notify_on_date} 
                                setIsSelected={setNotifyOnDate} 
                            />
                            Send the full analytics on a specific date
                            {notificationState.notify_on_date && (
                                <div>
                                    <DatePicker 
                                        selected={notificationState.notification_date} 
                                        onChange={handleDateChange} 
                                        dateFormat="MMMM d, yyyy" 
                                        minDate={new Date()}
                                    />
                                </div>
                            )}
                        </label>
                        {analytics &&
                        <div 
                            className={`broadcast-analytics-button ${isSending ? 'sent' : ''}`}
                            onClick={handleBroadcastAnalytics}
                        >
                            {isSending ? 'Sent' : 'Broadcast analytics now'}
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Responses</h4>
                    <p>Individual responses to the form questions</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="checkbox-group">
                        <label>
                            <CheckboxSettings isSelected={notificationState.notify_on_response} setIsSelected={setNotifyOnResponseValue} />
                            Send individual responses
                        </label>
                        {/* <label>
                            <CheckboxSettings isSelected={notificationState.notify_on_schedule_end} setIsSelected={setNotifyOnScheduleEnd} />
                            When the form is closed
                        </label> */}
                        {/* <label>
                            <CheckboxSettings isSelected={notificationState.notify_on_date} setIsSelected={setNotifyOnDate} />
                            Send the full analytics on a specific date
                            {notificationState.notify_on_date && (
                                <div>
                                    <DatePicker selected={notificationState.notification_date} onChange={handleDateChange} dateFormat="MMMM d, yyyy" minDate={new Date()}/>
                                </div>
                            )}
                        </label> */}

                    </div>

                </div>

            </div>

            {/* <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Notification Content</h4>
                    <p>Every notification will include this content, if it is ready and available. Leave blank to disable notifications.</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="checkbox-group checkbox-group-row">
                        <div className={`form-builder-notification-block ${notificationState.notification_includes.includes('raw') ? "selected" : ""}`} onClick={() => handleIncludeChange('raw')}>
                            Raw Responses (.csv)
                            <IoCheckmarkCircle className="form-builder-notification-block-icon-selected" />
                        </div>
                        <div className={`form-builder-notification-block ${notificationState.notification_includes.includes('summary') ? "selected" : ""}`} onClick={() => handleIncludeChange('summary')}>
                            Summary Text
                            <IoCheckmarkCircle className="form-builder-notification-block-icon-selected" />
                        </div>
                        <div className={`form-builder-notification-block ${notificationState.notification_includes.includes('charts') ? "selected" : ""}`} onClick={() => handleIncludeChange('charts')}>
                            Charts
                            <IoCheckmarkCircle className="form-builder-notification-block-icon-selected" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Email</h4>
                    <p>Select up to 10 email addresses to receive form notifications</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="email-input-container">
                        <input type="text" value={emailInput} onChange={handleEmailInputChange} onKeyDown={handleEmailInputKeyDown} onBlur={addEmail} placeholder="Enter email addresses" disabled={notificationState.notification_emails.length >= 10}/>
                        <div className="email-pills-container">
                            {notificationState.notification_emails.map((email, index) => (
                                <div key={index} className="email-pill">
                                    {email}
                                    <button onClick={() => removeEmail(email)}>&times;</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormBuilderNotifications;
import React, { useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { IoMdDownload } from "react-icons/io"

const FormResponseViewText = ({ questionId, questionData }) => {
    const { questionText, answers } = questionData;
    const nonBlankAnswers = answers.filter(a => a.trim() !== "")
    const [currentPage, setCurrentPage] = useState(1);
    const responsesPerPage = 10;

    const startIndex = (currentPage - 1) * responsesPerPage;
    const endIndex = startIndex + responsesPerPage;
    const paginatedAnswers = nonBlankAnswers.slice(startIndex, endIndex);
    const totalPages = Math.ceil(nonBlankAnswers.length / responsesPerPage);

    const downloadTextResponses = () => {
        const content = `${questionText}\n\n${nonBlankAnswers.join('\n\n')}`;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${questionText.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_responses.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="form-response-view-text-answers">
            <div className="form-response-view-chart-header">
                <h3>{questionText}</h3>
                <div className="form-response-view-controls">
                    {totalPages > 1 && (
                        <>
                            <FaChevronLeft
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                className={currentPage === 1 ? 'disabled' : ''}
                            />
                            <span>{currentPage} / {totalPages}</span>
                            <FaChevronRight
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                className={currentPage === totalPages ? 'disabled' : ''}
                            />
                        </>
                    )}
                    <IoMdDownload onClick={downloadTextResponses} />
                </div>
            </div>
            <div className="form-response-view-text-answers-container">
               {paginatedAnswers.map((answer, index) => (
                    <p key={index}>{answer}</p>
                ))} 
            </div>
            
        </div>
    );
};

export default FormResponseViewText;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { trial_plans, features } from '../../config/TRIAL_PLANS';
import { formatUSDCurrency } from '../../helpers/Helpers';
import './Pricing.css';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from "react-icons/io5";

export default function Pricing() {
    const navigate = useNavigate();

    const getPlanFeatures = (plan) => {
        return features.map(feature => ({
            ...feature,
            included: plan.features.includes(feature.key)
        }));
    };

    return (
        <div className="plans-and-pricing-container">
            <div className="plans-and-pricing-header">
                <h1>Plans & Pricing</h1>
                <p>Choose the plan that fits your organization's needs</p>
            </div>

            <div className="plans-and-pricing-cards">
                {trial_plans.map((plan) => (
                    <div 
                        key={plan.tetherform_plan_ref} 
                        className={`plans-and-pricing-card ${plan.display_name === 'Standard' ? 'plans-and-pricing-card-popular' : ''}`}
                    >
                        {plan.display_name === 'Standard' && (
                            <div className="plans-and-pricing-popular-tag">Most Popular</div>
                        )}
                        <div className="plans-and-pricing-card-header">
                            <h3>{plan.display_name}</h3>
                            <p className="plans-and-pricing-card-description">{plan.plan_description}</p>
                            <div className="plans-and-pricing-price">
                                <span className="plans-and-pricing-amount">{formatUSDCurrency(plan.monthly_price)}</span>
                                <span className="plans-and-pricing-period">/ month</span>
                            </div>
                        </div>

                        <button 
                            className="plans-and-pricing-cta-button"
                            onClick={() => navigate('/register')}
                        >
                            Get started
                        </button>

                        <div className="plans-and-pricing-card-benefits">
                            <div className="plans-and-pricing-card-benefit">
                                <IoCheckmarkCircleSharp />
                                <span>Up to {plan.monthly_responses.toLocaleString()} responses/mo</span>
                            </div>
                            {getPlanFeatures(plan)
                                .filter(feature => feature.included)
                                .map((feature, index) => (
                                    <div key={index} className="plans-and-pricing-card-benefit">
                                        <IoCheckmarkCircleSharp />
                                        <span>{feature.display_name}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))}
            </div>

            <div className="plans-and-pricing-guarantee">
                <p>All plans include a 60-day money back guarantee</p>
            </div>

            <div className="plans-and-pricing-comparison">
                <h2>Compare Plan Features</h2>
                <div className="plans-and-pricing-table">
                    <div className="plans-and-pricing-table-header">
                        <div className="plans-and-pricing-table-cell">Features</div>
                        {trial_plans.map(plan => (
                            <div key={plan.tetherform_plan_ref} className="plans-and-pricing-table-cell">
                                {plan.display_name}
                            </div>
                        ))}
                    </div>
                    {features.map(feature => (
                        <div key={feature.key} className="plans-and-pricing-table-row">
                            <div className="plans-and-pricing-table-cell">
                                <div className="plans-and-pricing-feature-name">{feature.display_name}</div>
                                <div className="plans-and-pricing-feature-description">{feature.description}</div>
                            </div>
                            {trial_plans.map(plan => (
                                <div key={plan.tetherform_plan_ref} className="plans-and-pricing-table-cell plans-and-pricing-table-cell-content">
                                    {plan.features.includes(feature.key) ? <IoCheckmarkCircleSharp style={{color: "var(--color-brand)"}} /> : <IoCloseCircleSharp style={{color: "var(--color-red)"}} />}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

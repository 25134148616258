import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './SetupAccount.css'
import '../home/Pricing.css'
import { trial_plans, features } from '../../config/TRIAL_PLANS'
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { formatUSDCurrency, formatNumber } from '../../helpers/Helpers';
import Loader from '../loaders/Loader'
import { initializeOrganization } from '../../api';

export default function SetupAccount() {
    const { logout, organizations, loading } = useAuth()
    const navigate = useNavigate()
    const [page, setPage] = useState(() => { return 1 })
    const [selectedPlan, setSelectedPlan] = useState(() => { return "tetherform_plan_ref" })
    const [firstName, setFirstName] = useState(() => { return "" })
    const [organizationName, setOrganizationName] = useState(() => { return "" })
    const [validPageOne, setValidPageOne] = useState(() => { return false })
    const handleNevermind = () => {
        logout()
        navigate("/")
    }
    const checkPageOneValidation = useCallback(() => {
        try {
            if (organizationName === "" || firstName === "") return setValidPageOne(false)
            if (organizationName.trim() === "") return setValidPageOne(false)
            if (firstName.trim() === "") return setValidPageOne(false)
            setValidPageOne(true)
        } catch (error) {
            setValidPageOne(false)
        }
    }, [organizationName, firstName])
    const handlePageOneButtonPressing = (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            if (validPageOne) return setPage(2)
        }
    }
    const submitOrganizationRequest = () => {
        setPage(3)
        let planId = selectedPlan
        if (!["plan_24q4_starter", "plan_24q4_standard", "plan_24q4_pro", "plan_24q4_premium"].includes(planId)) planId = "plan_24q4_starter"
        initializeOrganization(firstName, organizationName, planId, false) // the false is for "isAnnualPlan" (only monthly plans available rn)
        .then((res) => {
            if (!res.data) {
                // Something went wrong
                setPage(4)
                return
            }
            if (res.data.url) {
                window.location.href = res.data.url
            }
        })
        .catch((err) => { return setPage(4) })
    }
    useEffect(() => {
        checkPageOneValidation()
    }, [checkPageOneValidation])
    useEffect(() => {
        if (organizations.length > 0) setPage(2)
    }, [loading, organizations])
    return (
        <div className="setup-account">
            {loading && <Loader/>}
            {!loading &&
            <div className="setup-account-form-container">
                {page < 3 && <div className="setup-account-form-progress">{page}/2</div>}

                {/* PAGE 1: GENERAL INFORMATION */}
                {page === 1 && !loading &&
                <div>
                    <h2>Let's finish setting up your account</h2>
                    <p>Tell us more about you and your business</p>
                    <div className="setup-account-form-group">
                        <label htmlFor="email">First name</label>
                        <input type="text" onKeyDown={(e) => handlePageOneButtonPressing(e)} id="name" placeholder="Enter your name" autoFocus maxLength={30} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div className="setup-account-form-group">
                        <label htmlFor="email">Organization name</label>
                        <input type="text" onKeyDown={(e) => handlePageOneButtonPressing(e)} id="orgname" placeholder="Acme, Inc" maxLength={80} value={organizationName} onChange={(e) => setOrganizationName(e.target.value)}/>
                    </div>
                    <div className="setup-account-form-buttons">
                        <button 
                            onClick={() => setPage(2)}
                            style={!validPageOne ? {backgroundColor: "var(--color-grey)"} : {}}
                            disabled={!validPageOne}
                        >
                            Continue
                        </button>
                        <span onClick={handleNevermind}>Nevermind</span>
                    </div>
                </div>
                }

                {/* PAGE 2: TRIAL PLAN SELECTION */}
                {page === 2 && !loading &&
                <div>
                    <div className="plans-and-pricing-header">
                        <h1>Choose your plan</h1>
                        <p>Your subscription will begin with a 14-day free trial. Cancel at any time.</p>
                    </div>

                    <div className="plans-and-pricing-cards">
                        {trial_plans.map((plan) => (
                            <div 
                                key={plan.tetherform_plan_ref} 
                                className={`plans-and-pricing-card ${
                                    selectedPlan === plan.tetherform_plan_ref ? 'plans-and-pricing-card-popular' : ''
                                }`}
                                onClick={() => setSelectedPlan(plan.tetherform_plan_ref)}
                            >
                                <div className="plans-and-pricing-card-header">
                                    <h3>{plan.display_name}</h3>
                                    <p className="plans-and-pricing-card-description">
                                        {plan.plan_description}
                                    </p>
                                    <div className="plans-and-pricing-price">
                                        <span className="plans-and-pricing-amount">
                                            {formatUSDCurrency(plan.monthly_price)}
                                        </span>
                                        <span className="plans-and-pricing-period">/ month</span>
                                    </div>
                                </div>

                                <div className="plans-and-pricing-card-benefits">
                                    <div className="plans-and-pricing-card-benefit">
                                        <IoCheckmarkCircleSharp />
                                        <span>Up to {formatNumber(plan.monthly_responses)} responses/mo</span>
                                    </div>
                                    {plan.features.map((featureKey) => {
                                        const feature = features.find(f => f.key === featureKey);
                                        return feature && (
                                            <div key={featureKey} className="plans-and-pricing-card-benefit">
                                                <IoCheckmarkCircleSharp />
                                                <span>{feature.display_name}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="setup-account-form-buttons">
                        <button onClick={submitOrganizationRequest}>Launch Tetherform</button>
                        {organizations.length < 1 && <span onClick={() => setPage(1)}>Back</span>}
                    </div>
                </div>
                }

                {/* PAGE 3: THINKING */}
                {page === 3 &&
                <div style={{paddingTop: "32px"}}>
                    <Loader/>
                </div>
                }

                {/* PAGE 4: SOMETHING WENT WRONG */}
                {page === 4 &&
                <div>
                    <h2>Oh man...</h2>
                    <p>You aren't able to create an organization right now. Please reach out to our support team if you think this is an error</p>
                    <div style={{marginTop: "40px"}}>Support email: <a href="mailto:support@tetherform.com">support@tetherform.com</a></div>
                    <div className="setup-account-form-buttons">
                        <button onClick={handleNevermind}>Return</button>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    )
}

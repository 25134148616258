import React, { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganization } from '../../../context/OrganizationContext';
import { uploadImportFile, confirmImportJob } from '../../../api';
import { IoCloudUpload, IoWarning, IoClose, IoDocument } from "react-icons/io5";
import './ImportWizard.css';
import { formatNumberAsString } from '../../../helpers/Helpers';
import { MdChevronLeft } from "react-icons/md";
import ImportWizardExampleTable from './ImportWizardExampleTable';

const ACCEPTED_FILE_TYPES = '.csv,.xlsx,.xls';
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

const QUESTION_TYPES = [
    { value: 'text', label: 'Text' },
    { value: 'rating', label: 'Rating' },
    { value: 'multiple-choice', label: 'Multiple Choice' },
    { value: 'select-multiple', label: 'Multiple Select' },
    { value: 'do_not_import', label: 'Do not import' }
];

const RATING_SCALES = [
    { value: 'auto', label: 'Auto-detect' },
    { value: '1-5', label: '1 to 5' },
    { value: '1-10', label: '1 to 10' },
    { value: '1-100', label: '1 to 100' }
];

const ImportWizard = () => {
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const { organization } = useOrganization();
    const fileInputRef = useRef();
    
    const [step, setStep] = useState(1);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [importData, setImportData] = useState({
        columns: [],
        sampleData: [],
        total_responses: 0
    });
    const [columnMappings, setColumnMappings] = useState(new Map());
    const [ratingScales, setRatingScales] = useState(new Map());
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [importDetails, setImportDetails] = useState({
        name: '',
        description: ''
    });
    const [responseCount, setChargedResponseCount] = useState(0);
    const [showExampleTable, setShowExampleTable] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
        await handleFileUpload(file);
    };

    const handleFileSelect = async (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        await handleFileUpload(file);
    };

    const validateFile = (file) => {
        if (!file) return 'Please select a file';
        
        const fileType = file.name.split('.').pop().toLowerCase();
        if (!['csv', 'xlsx', 'xls'].includes(fileType)) {
            return 'Invalid file type. Please upload a CSV or Excel file.';
        }

        if (file.size > MAX_FILE_SIZE) {
            return 'File size exceeds 50MB limit. Please upload a smaller file.';
        }

        return null;
    };

    const handleFileUpload = async (file) => {
        setError(null);
        setIsLoading(true);
        setUploadProgress(0);

        const validationError = validateFile(file);
        if (validationError) {
            setError(validationError);
            setIsLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('organizationId', organization._id);

            const response = await uploadImportFile(formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            if (response.data) {
                // Check if we have enough capacity
                const foundResponses = response.data.total_responses;
                const remainingResponses = organization.monthly_responses - organization.total_responses;

                if (foundResponses < 1) {
                    setError(`Could not find any responses to upload. Use a different file.`);
                    setIsLoading(false);
                    setSelectedFile(null);
                    if (fileInputRef.current) fileInputRef.current.value = '';
                    return;
                }
                
                if (foundResponses > remainingResponses) {
                    setError(`This file contains ${formatNumberAsString(foundResponses)} responses, but you only have ${formatNumberAsString(remainingResponses)} responses remaining in your monthly capacity. Please upgrade your plan or try again next month.`);
                    setIsLoading(false);
                    setSelectedFile(null);
                    if (fileInputRef.current) fileInputRef.current.value = '';
                    return;
                }

                setChargedResponseCount(foundResponses);
                
                // Initialize column mappings with suggested types or default to 'text'
                const newMappings = new Map();
                response.data.columns.forEach(column => {
                    const suggestedType = response.data.suggestedMapping[column] || 'text';
                    newMappings.set(column, suggestedType);
                });
                setColumnMappings(newMappings);

                setImportData({
                    columns: response.data.columns,
                    sampleData: response.data.sampleData,
                    total_responses: response.total_responses
                });
                
                // Don't automatically advance to step 2
            } else {
                throw new Error(response.error || 'Failed to upload file');
            }
        } catch (err) {
            setError(err.message || 'Failed to upload file');
            setSelectedFile(null);
            if (fileInputRef.current) fileInputRef.current.value = '';
        } finally {
            setIsLoading(false);
            setUploadProgress(0);
        }
    };

    const handleRetry = () => {
        setError(null);
        if (selectedFile) {
            handleFileUpload(selectedFile);
        }
    };
    const sampleData = [
        ['Email', 'What is your first Name', 'What is your last Name', 'What is your phone number', 'Response Date', 'How satisfied are you with our product', 'Do you have any comments you would like to share with our team?', 'If we released another version, would you be interested in learning more?'],
        ['john.doe@example.com', 'John', 'Doe', '555-0101', '2024-03-15', 'Very Satisfied', 'The product is great', 'Yes'],
        ['jane.smith@example.com', 'Jane', 'Smith', '555-0102', '2024-03-15', 'Satisfied', 'Easy to use interface', 'Maybe'],
        ['bob.wilson@example.com', 'Bob', 'Wilson', '555-0103', '2024-03-15', 'Neutral', 'Could use more features', 'No'],
        ['alice.brown@example.com', 'Alice', 'Brown', '555-0104', '2024-03-15', 'Very Satisfied', 'Customer support is excellent', 'Yes'],
        ['sam.jones@example.com', 'Sam', 'Jones', '555-0105', '2024-03-15', 'Satisfied', 'Good value for money', 'Yes']
    ];
    const handleMappingChange = (column, type) => {
        setColumnMappings(prev => {
            const newMap = new Map(prev);
            newMap.set(column, type);
            
            // If changing away from rating type, remove the rating scale
            if (type !== 'rating') {
                setRatingScales(prev => {
                    const newScales = new Map(prev);
                    newScales.delete(column);
                    return newScales;
                });
            } else {
                // If changing to rating type, set default scale to auto
                setRatingScales(prev => {
                    const newScales = new Map(prev);
                    newScales.set(column, 'auto');
                    return newScales;
                });
            }
            
            return newMap;
        });
    };

    const handleRatingScaleChange = (column, scale) => {
        setRatingScales(prev => {
            const newMap = new Map(prev);
            newMap.set(column, scale);
            return newMap;
        });
    };

    const handleDetailsChange = (field, value) => {
        setImportDetails(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleConfirm = async () => {
        setError(null);
        setIsLoading(true);

        try {
            // Get the file ready
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('organizationId', organization._id);
            formData.append('title', importDetails.name.trim())
            formData.append('description', importDetails.description.trim())

            // Convert mappings to the new format
            const mappings = [];
            const ignoredColumns = [];
            
            importData.columns.forEach((columnName, index) => {
                const mappedType = columnMappings.get(columnName);
                if (!mappedType) return; // Skip unmapped columns
                
                if (mappedType === 'do_not_import') {
                    ignoredColumns.push({
                        columnIndex: index,
                        columnName
                    });
                } else {
                    const mapping = {
                        columnIndex: index,
                        columnName,
                        mappedType
                    };
                    
                    // Add rating scale if this is a rating question
                    if (mappedType === 'rating') {
                        mapping.ratingScale = ratingScales.get(columnName) || 'auto';
                    }
                    
                    mappings.push(mapping);
                }
            });

            formData.append('file_metadata', JSON.stringify({
                column_mapping: mappings,
                ignored_columns: ignoredColumns,
                organizationId: organization._id,
            }));

            const response = await confirmImportJob(formData);

            if (response.data) {
                if (response.data.import_id) {
                    return navigate(`/o/${organizationId}/imports/${response.data.import_id}`);
                }
            }

            throw new Error(response.error || 'Failed to confirm import');

        } catch (err) {
            setError(err.message || 'Failed to confirm import');
        } finally {
            setIsLoading(false);
        }
    };
    const downloadSampleCsv = () => {
        

        // Convert to CSV string
        const csvContent = sampleData.map(row => row.join(',')).join('\n');
        
        // Create blob and download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'tetherform_sample_import.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleNextStep = () => {
        if (step === 1) {
            // Only proceed if we have both a file and a title
            if (!selectedFile) {
                setError('Please upload a file first');
                return;
            }
            if (!importDetails.name.trim()) {
                setError('Please enter a title for this import');
                return;
            }
            setError(null);
            setStep(2);
        } else if (step === 2) {
            setStep(3);
        }
    };

    const handlePreviousStep = () => {
        setError(null);
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const renderStep1 = () => (
        <div className="import-step">
            <h2>Import Details</h2>
            <div className="import-details-container">
                <div className="details-form">
                    <div className="import-wizard-form-group">
                        <label htmlFor="import-name">Name *</label>
                        <input
                            id="import-name"
                            type="text"
                            value={importDetails.name}
                            onChange={(e) => handleDetailsChange('name', e.target.value)}
                            placeholder="Enter a name for this import"
                            className="import-wizard-form-input"
                            disabled={isLoading}
                            autoFocus={true}
                        />
                    </div>
                    <div className="import-wizard-form-group">
                        <label htmlFor="import-description">Description (Optional)</label>
                        <textarea
                            id="import-description"
                            value={importDetails.description}
                            onChange={(e) => handleDetailsChange('description', e.target.value)}
                            placeholder="Enter a description for this import"
                            className="import-wizard-form-textarea"
                            rows={4}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                <div 
                    className={`upload-area ${isDragging ? 'dragging' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        accept={ACCEPTED_FILE_TYPES}
                        style={{ display: 'none' }}
                    />
                    {isLoading ? (
                        <div className="upload-progress">
                            <div 
                                className="progress-bar"
                                style={{ width: `${uploadProgress}%` }}
                            />
                            <span>{uploadProgress}%</span>
                        </div>
                    ) : selectedFile ? (
                        <>
                            <div className="selected-file">
                                <IoDocument className="file-icon" />
                                <span>{selectedFile.name}</span>
                            </div>
                            <button 
                                onClick={() => fileInputRef.current.click()}
                                className="change-file-button"
                            >
                                Change file
                            </button>
                        </>
                    ) : (
                        <>
                            <IoCloudUpload className="upload-icon" />
                            <p>Drag and drop your .csv or .xlsx file here, or</p>
                            <button 
                                onClick={() => fileInputRef.current.click()}
                                className="upload-button"
                            >
                                Browse Files
                            </button>
                            <p className="supported-formats">
                                Make sure your data is formatted correctly. Place questions in the top row and answers in every row below.
                                <br/> 
                                <span onClick={downloadSampleCsv}>Download an example file</span>
                                {" • "}
                                <span onClick={() => setShowExampleTable(true)}>View example format</span>
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="wizard-actions">
                <div></div>
                <button 
                    onClick={handleNextStep}
                    className="primary-button"
                    disabled={isLoading || !selectedFile || !importDetails.name.trim()}
                >
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep2 = () => (
        <div className="import-step">
            <h2>Column Mapping</h2>
            <p>Map your file columns to question types.</p>
            <div className="mapping-container">
                <div className="mapping-header">
                    <span className="mapping-header-label">Question</span>
                    <span className="mapping-header-label">Example Answer</span>
                    <span className="mapping-header-label">Question Type</span>
                </div>
                {importData && importData.columns.map((column, index) => {
                    const mappedType = columnMappings.get(column) || 'text';
                    const isIgnored = mappedType === 'do_not_import';
                    const isRating = mappedType === 'rating';
                    
                    return (
                        <div key={column} className={`mapping-row ${isIgnored ? 'ignored' : ''}`}>
                            <div className="column-name">
                                {column}
                                {isIgnored && <span className="ignored-badge">Ignored</span>}
                            </div>
                            <div className="column-sample">
                                {importData.sampleData[0][column]}
                            </div>
                            <div className="mapping-controls">
                                <div className="mapping-selects">
                                    <select
                                        value={mappedType}
                                        onChange={(e) => handleMappingChange(column, e.target.value)}
                                        className={`mapping-select ${isIgnored ? 'ignored' : ''}`}
                                        disabled={isLoading}
                                    >
                                        {QUESTION_TYPES.map(type => (
                                            <option key={type.value} value={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </select>
                                    {isRating && (
                                        <select
                                            value={ratingScales.get(column) || 'auto'}
                                            onChange={(e) => handleRatingScaleChange(column, e.target.value)}
                                            className="mapping-select rating-scale-select"
                                            disabled={isLoading}
                                        >
                                            {RATING_SCALES.map(scale => (
                                                <option key={scale.value} value={scale.value}>
                                                    {scale.label}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="wizard-actions">
                <button 
                    onClick={handlePreviousStep}
                    className="secondary-button"
                    disabled={isLoading}
                >
                    Back
                </button>
                <button 
                    onClick={handleNextStep}
                    className="primary-button"
                    disabled={isLoading || columnMappings.size === 0}
                >
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep4 = () => {
        const remainingResponses = organization.monthly_responses - organization.total_responses;
        const willExceedLimit = responseCount > remainingResponses;
        const newTotalAfterImport = organization.total_responses + responseCount;

        return (
            <div className="import-step">
                <h2>Confirm Import Details</h2>
                <div className="confirmation-details">
                    <div className="confirmation-section">
                        <h3>Import Information</h3>
                        <div className="detail-row">
                            <span className="detail-label">Name:</span>
                            <span className="detail-value">{importDetails.name}</span>
                        </div>
                        {importDetails.description && (
                            <div className="detail-row">
                                <span className="detail-label">Description:</span>
                                <span className="detail-value">{importDetails.description}</span>
                            </div>
                        )}
                        <div className="detail-row">
                            <span className="detail-label">Responses to Import:</span>
                            <span className="detail-value">{formatNumberAsString(responseCount)}</span>
                        </div>
                    </div>

                    <div className="confirmation-section">
                        <h3>Organization Capacity</h3>
                        <div className="detail-row">
                            <span className="detail-label">Current Usage:</span>
                            <span className="detail-value">
                                {formatNumberAsString(organization.total_responses)} / {formatNumberAsString(organization.monthly_responses)}
                            </span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Remaining Capacity:</span>
                            <span className="detail-value">{formatNumberAsString(remainingResponses)}</span>
                        </div>
                        {!willExceedLimit && (
                            <div className="detail-row">
                                <span className="detail-label">New Total After Import:</span>
                                <span className="detail-value">{formatNumberAsString(newTotalAfterImport)} / {formatNumberAsString(organization.monthly_responses)}</span>
                            </div>
                        )}
                    </div>

                    {willExceedLimit && (
                        <div className="capacity-warning">
                            <p>⚠️ This import would exceed your monthly response limit.</p>
                            <p>You need {formatNumberAsString(responseCount)} responses but only have {formatNumberAsString(remainingResponses)} remaining.</p>
                            <p>Please upgrade your plan or try again next month.</p>
                        </div>
                    )}
                </div>
                <div className="wizard-actions">
                    <button 
                        onClick={() => setStep(2)} 
                        className="secondary-button"
                        disabled={isLoading}
                    >
                        Back
                    </button>
                    <button 
                        onClick={handleConfirm} 
                        className="primary-button"
                        disabled={isLoading || willExceedLimit}
                    >
                        {isLoading ? (
                            <span className="button-loading">
                                <span className="loading-dots">Processing</span>
                            </span>
                        ) : (
                            'Start Import'
                        )}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="import-wizard">
            <div className="wizard-header">
                <div className="wizard-header-back-button-container"><div className="wizard-header-back-button" onClick={() => navigate(-1)}><MdChevronLeft/>Go back</div></div>
                <h1>Import Survey Responses</h1>
                <p>Analyze external survey data with Tetherform for insights and recommendations.</p>
                <div className="wizard-steps">
                    <div className={`step ${step >= 1 ? 'active' : ''}`}>1. Upload & Details</div>
                    <div className={`step ${step >= 2 ? 'active' : ''}`}>2. Map Columns</div>
                    <div className={`step ${step >= 3 ? 'active' : ''}`}>3. Confirm</div>
                </div>
            </div>
            {error && (
                <div className="error-message">
                    <div className="error-content">
                        <IoWarning className="error-icon" />
                        <span>{error}</span>
                        <button 
                            className="error-close"
                            onClick={() => setError(null)}
                            aria-label="Dismiss error"
                        >
                            <IoClose />
                        </button>
                    </div>
                    {selectedFile && (
                        <button 
                            className="retry-button"
                            onClick={handleRetry}
                        >
                            Retry Upload
                        </button>
                    )}
                </div>
            )}
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep4()}
            
            {showExampleTable && (
                <ImportWizardExampleTable 
                    data={sampleData} 
                    onClose={() => setShowExampleTable(false)}
                />
            )}
        </div>
    );
};

export default ImportWizard; 
import React, { useState } from 'react';
import { 
    FaList, 
    FaStar, 
    FaCheckSquare, 
    FaFont, 
    FaHeading, 
} from 'react-icons/fa';
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import './FormBuilderToolSet.css';

const menuItems = [
    {
        id: 'input-elements',
        label: 'Questions',
        items: [
            {
                id: 'text',
                icon: FaFont,
                label: 'Text'
            },
            {
                id: 'multiple-choice',
                icon: FaList,
                label: 'Multiple Choice'
            },
            {
                id: 'select-multiple',
                icon: FaCheckSquare,
                label: 'Select Multiple'
            },
            {
                id: 'rating',
                icon: FaStar,
                label: 'Rating'
            },
        ]
    },
    {
        id: 'layout-elements',
        label: 'Layouts',
        items: [
            {
                id: 'section-heading',
                icon: FaHeading,
                label: 'Section Heading'
            },
        ]
    }
];

function FormBuilderToolSetSection({ onSelectItem, section }) {
    const [isCollapsed, setIsCollapsed] = useState(() => { return false })
    return (
        <div className="form-builder-tool-set-section">
            <div className="form-builder-tool-set-section-header" onClick={() => setIsCollapsed(!isCollapsed)}>
                <h3>{section.label}</h3>
                {!isCollapsed ? <IoChevronDownOutline/> : <IoChevronUpOutline/>}
            </div>
            {!isCollapsed && (
                <div className="form-builder-tool-set-grid">
                    {section.items.map((item) => {
                        const Icon = item.icon;
                        return (
                            <div
                                key={item.id}
                                className="form-builder-tool-set-item"
                                onClick={() => onSelectItem(item.id)}
                            >
                                <div className="form-builder-tool-set-item-icon">
                                    <Icon />
                                </div>
                                <div className="form-builder-tool-set-item-label">
                                    {item.label}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )
}

export default function FormBuilderToolSet({ onSelectItem }) {
    return (
        <div className="form-builder-tool-set">
            <div className="form-builder-tool-set-sections">
                {menuItems.map((section) => (
                    <FormBuilderToolSetSection 
                        onSelectItem={onSelectItem} 
                        section={section} 
                        key={section.id} 
                    />
                ))}
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const OmittedAnswersView = ({ omittedAnswers }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const responsesPerPage = 10;

    const startIndex = (currentPage - 1) * responsesPerPage;
    const endIndex = startIndex + responsesPerPage;
    const paginatedAnswers = omittedAnswers.slice(startIndex, endIndex);
    const totalPages = Math.ceil(omittedAnswers.length / responsesPerPage);

    return (
        <div className="form-response-view-text-answers form-response-view-text-answers-others">
            <div className="form-response-view-chart-header">
                <h3>Other Answers</h3>
                {totalPages > 1 && (
                    <div className="form-response-view-controls">
                        <FaChevronLeft
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            className={currentPage === 1 ? 'disabled' : ''}
                        />
                        <span>{currentPage} / {totalPages}</span>
                        <FaChevronRight
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            className={currentPage === totalPages ? 'disabled' : ''}
                        />
                    </div>
                )}
            </div>
            <div className="form-response-view-text-answers-container">
                {paginatedAnswers.map((answer, index) => (
                    <p key={index}>{answer}</p>
                ))}
            </div>
        </div>
    );
};

export default OmittedAnswersView; 
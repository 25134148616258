import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import './Landing.css'
import Footer from '../footer/Footer';
import { useAuth } from '../../context/AuthContext'
import FAQ from '../home/FAQ';
import { FaTimes, FaArrowRight } from 'react-icons/fa';
import { IoIosArrowUp } from 'react-icons/io';

function BackToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <div className="landing-back-to-top" onClick={scrollToTop} role="button" aria-label="Back to top">
                    <IoIosArrowUp size={24} />
                </div>
            )}
        </>
    );
}

export default function Landing() {
    const navigate = useNavigate()
    const { isAuthenticated } = useAuth()
    const [showVideoModal, setShowVideoModal] = useState(false);
    const insightItems = [
        {
            headline: "Make keynote speeches a highlight at events",
            summary: "Many attendees appreciated the keynote speeches, which were their favorite moments. Focus on making these speeches a key component of future events. Invite well-known speakers or leaders in the industry to keep people engaged and generate excitement.",
            type: "insight"
        },
        {
            headline: "Improve event logistics and organization",
            summary: "Logistics received a mixed score of 3.67. Work on improving crucial areas, such as parking availability and Wi-Fi access. Use feedback to identify specific challenges and develop solutions to create a better experience for all attendees.",
            type: "insight"
        },
        {
            headline: "Boost motivation by expanding discounted programs",
            summary: "Offering more discounted programs is likely to engage members, as 74% find them valuable, scoring an average of 3.74. Exploring additional discounts or package deals may increase access to professional development and draw a larger audience.",
            type: "insight"
        },
        {
            headline: "High member satisfaction leads to strong referrals",
            summary: "Most respondents report feeling very satisfied with their experiences, giving an average satisfaction rating of 4 out of 5. Along with this, they are likely to recommend your organization, with an average score of 4.56. Many members take pride in their association.",
            type: "insight"
        },
        {
            headline: "Event attendance preferences show diversity",
            summary: "Members express varied preferences for event attendance, with 15 favoring in-person events, 3 preferring virtual formats, and 9 choosing based on the day and time. This variety points to a need for well-rounded programming.",
            type: "insight"
        },
        {
            headline: "Participants found diverse ways to learn about the event",
            summary: "Attendees learned about the event through various channels, with the website being the most popular, followed by email and social media.",
            type: "insight"
        },
    ];
    const handlePlayVideo = () => {
        setShowVideoModal(true);
    };
    const handleCloseVideo = () => {
        setShowVideoModal(false);
    };
    useEffect(() => {
        document.title = "Tetherform - Event Feedback Analysis Platform"
    }, [])
    if (isAuthenticated) {
        return <Navigate to="/welcome" replace />
    }
    const handleRegistrationClick = () => {
        navigate('/register')
    }
    return (
        <div className="landing-container">
            {/* Hero Section */}
            <section className="landing-hero">
                <div className="landing-hero-content">
                    <h1>Analyze survey results in minutes</h1>
                    <h2>Tetherform lets you import survey data to get deep insights, analytics, and recommendations for your organization. Start for free.</h2>
                    <div className="landing-hero-cta">
                        <button className="landing-primary-button" onClick={handleRegistrationClick}>
                            Get started
                            <FaArrowRight className="landing-cta-arrow" />
                        </button>
                        <p className="landing-hero-microcopy">Free 14-day trial. Cancel any time.</p>
                    </div>
                </div>
                <div className="landing-hero-visual">
                    <img 
                        src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2022%20Tetherform%20on%20Screens.png"
                        alt="Tetherform Analytics Dashboard"
                        className="landing-hero-image"
                        loading="lazy"
                        width="1400"
                        height="785"
                    />
                </div>
            </section>

            <section className="landing-how-it-works landing-section-plate landing-import-providers">
                <h2>Import existing surveys</h2>
                <div className="landing-platform-support">
                    <span>Compatible with most major providers</span>
                    <div className="landing-platform-icons">
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/competitor-logos/Goldie_White_RGB.png" alt="SurveyMonkey" />
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/competitor-logos/Google_Forms_2020_Logo.svg.png" alt="Google Forms" />
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/competitor-logos/jotform-icon-dark-400x400.png" alt="Jotform" />
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/competitor-logos/Microsoft_Forms_(2019-present).svg.png" alt="Microsoft Forms" />
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/competitor-logos/typeform%20logo.png" alt="Typeform" />
                    </div>
                </div>
                <div className="landing-how-it-works-cta-button" onClick={handlePlayVideo}>Watch a demo</div>
            </section>

            <section className="landing-how-it-works landing-section-plate landing-design-your-own">
                <h2>Or design your own</h2>
                <div className="landing-platform-support">
                    <span>Create custom surveys or deploy from a template in seconds.</span>
                    <div>
                        <div className="landing-how-it-works-screenshot">
                            <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2024%20Tetherform%20Build%20Your%20Own%202-2.png" alt="Build your own survey" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="landing-how-it-works landing-section-plate landing-section-insights-and-recommendations">
                <h2>Get key insights and recommendations</h2>
                <div className="landing-platform-support">
                    <span>Find strategies to increase membership renewals, event turnout, and more - all based on your data.</span>
                    <div className="landing-insights-and-recommendations">
                        {insightItems.map((item, index) => (
                            <div 
                                key={index}
                                className={`form-builder-analytics-finding-item form-builder-analytics-finding-item-${item.type}`}
                            >
                                <div className="form-builder-analytics-finding-headline">{item.headline}</div>
                                <div className="form-builder-analytics-finding-summary">{item.summary}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="landing-how-it-works landing-section-plate landing-section-insights-and-recommendations">
                <h2>Built for busy organizations</h2>
                <div className="landing-platform-support">
                    <span>Stacked with different tools for helping you understand your customers, members, and guests.</span>
                    <div className="landing-steps">
                        <div className="landing-step">
                            <div className="landing-step-image"> <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2024%20Tetherform%20Upload%20Feature.png" alt="Tetherform import survey portal" /> </div>
                            <h3>Create or import your survey</h3>
                            <p>Build a new survey or import existing results from any platform</p>
                        </div>
                        <div className="landing-step">
                            <div className="landing-step-image"> <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2024%20Tetherform%20Analytics%20Feature.png" alt="Tetherform analytics feature" /> </div>
                            <h3>Get detailed reports</h3>
                            <p>Tetherform classifies and analyzes your data to provide personalized insights and recommendations.</p>
                        </div>
                        <div className="landing-step">
                            <div className="landing-step-image"> <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2024%20Tetherform%20Response%20Feature.png" alt="Tetherform visualization feature" /> </div>
                            <h3>Visualize every responses</h3>
                            <p>See the raw data at a glance for traditional review. Export the results as needed.</p>
                        </div>
                        <div className="landing-step">
                            <div className="landing-step-image"> <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2025%2001%2024%20Tetherform%20Template%20Feature.png" alt="Tetherform survey temlate feature" /> </div>
                            <h3>Build custom surveys</h3>
                            <p>Generate useful surveys on your own or deploy one of our proven templates in seconds.</p>
                        </div>
                    </div>
                </div>
                <div className="landing-how-it-works-cta-button" onClick={handleRegistrationClick}>Get started free</div>
            </section>

            <FAQ/>
            <Footer/>
            <BackToTop />

            {showVideoModal && (
                <div className="landing-video-modal-overlay" onClick={handleCloseVideo}>
                    <button className="landing-video-modal-close" onClick={handleCloseVideo}>
                        <FaTimes />
                    </button>
                    <div className="landing-video-modal-content" onClick={e => e.stopPropagation()}>
                        <iframe 
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube.com/embed/nzwvZZ2LvP8?si=kpOOlPKtw7CFusYX&autoplay=1" 
                            title="Tetherform Demo Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    )
}
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganization } from '../../../context/OrganizationContext';
import { getImportDetails, updateImportedSurvey } from '../../../api';
import { FaArrowLeft } from 'react-icons/fa';
import { IoArrowBack } from "react-icons/io5";
import FormBuilderAnalytics from '../../form/FormBuilderAnalytics';
import FormResponseView from '../../form/FormResponseView';
import Loader from '../../loaders/Loader';
import { format, parseISO } from 'date-fns'
import { TbCsv } from "react-icons/tb";
import './ImportDetails.css';
import '../../form/FormBuilder.css'

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_NAV':
            return { ...state, selectedNav: action.payload };
        case 'SET_FORM_TITLE':
            return { ...state, dataset_name: action.payload };
        default:
            return state;
    }
};
const initialState = {
    dataset_name: 'New Dataset',
    status: 'draft',
    selectedNav: 'main',
};

export default function ImportDetails() {
    const { importId, organizationId } = useParams();
    const [state, dispatch] = useReducer(formReducer, initialState);
    const navigate = useNavigate();
    const { organization } = useOrganization();
    const [importData, setImportData] = useState(null);
    const [analytics, setAnalytics] = useState(false);
    const stateRef = useRef(state);
    const [title, setTitle] = useState(state.dataset_name);
    const [isEditing, setIsEditing] = useState(false);
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const inputRef = useRef(null);
    const timerRef = useRef(null);
    // const settingsMenuRef = useRef(null);
    const changesPendingRef = useRef(false);
    const [error, setError] = useState(null);
    const navItems = [
        { id: 'main', title: 'Summary' },
        { id: 'responses', title: 'Responses' },
        { id: 'analytics', title: 'Analytics' },
    ];
    const fetchData = async () => {
        try {
            setError(null);
            const importResponse = await getImportDetails(importId);
            setImportData(importResponse.data);
            setTitle(importResponse.data.dataset_name)
            dispatch({ type: 'SET_FORM_TITLE', payload: importResponse.data.dataset_name });
            if (!importResponse.data) return
            if (importResponse.data.analytics) setAnalytics(importResponse.data.analytics)
            if (importResponse.data.responses) setResponses(importResponse.data.responses)
        } catch (err) {
            console.error('Import details fetch error:', err);
            setError(err.message || 'Failed to load import details');
        } finally {
            setIsLoading(false);
        }
    };
    const handleFormTitleChange = (newTitle) => {
        dispatch({ type: 'SET_FORM_TITLE', payload: newTitle });
        saveUpdatesAfterDelay();
    };
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const saveUpdatesAfterDelay = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        
        changesPendingRef.current = true;
        
        timerRef.current = setTimeout(() => {
            if (changesPendingRef.current) {
                if (!importId) return
                updateImportedSurvey(importId, { dataset_name: title } )
                .then((res) => {
                    changesPendingRef.current = false;
                })
                .catch((err) => {
                    changesPendingRef.current = false;
                })
            }
        }, 500);
    };
    const handleTitleBlur = () => {
        setIsEditing(false);
        handleFormTitleChange(title || 'Untitled Form');
    };
    const handleTitleClick = () => {
        setIsEditing(true);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleTitleBlur();
        }
    };
    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    useEffect(() => {
        setTitle(state.dataset_name);
    }, [state.dataset_name]);
    useEffect(() => {
        fetchData();
        let pollInterval;
        if (importData?.status === 'processing' || importData?.status === 'analyzing') {
            pollInterval = setInterval(fetchData, 30000);
        }
        return () => {
            if (pollInterval) clearInterval(pollInterval);
        };
    // eslint-disable-next-line
    }, [importId, importData?.status]);
    const handleBackClick = () => {
        navigate(`/o/${organizationId}/imports`);
    };
    const handleNavClick = (id) => {
        if (isValidNavValue(id)) {
            dispatch({ type: 'SET_SELECTED_NAV', payload: id });
            navigate(`/o/${organizationId}/imports/${importId}?v=${id}`, { replace: true });
        }
    };
    const isValidNavValue = (value) => {
        const validNavs = ['main', 'analytics', 'responses'];
        return validNavs.includes(value);
    };
    const renderContent = () => {
        if (isLoading) {
            return <div style={{marginTop: "200px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}><Loader/>Loading form...</div>;
        }
        switch (state.selectedNav) {
            case 'main':
                return (
                    <div className="import-details-outer">
                        <div className="import-details-outer-backdrop"></div>
                        {(isLoading || importData.status !== "completed") &&
                        <div className="import-details-loading-state">
                            {!importData.status !== "failed" && <div className="import-details-loader"></div>}
                            {importData.status === "failed" && <div>Something went wrong analyzing the data.</div>}
                            {["processing", "pending", "analyzing"].includes(importData.status) && <div>Analyzing data...</div>}
                        </div>
                        }
                        {!isLoading && importData.status === "completed" &&
                        <div className="import-details-summary-container">
                            <div className="import-details-summary-container-inner">
                                {/* SUMMARY */}
                                {analytics.executive_summary &&
                                <div className="import-details-summary-executive">
                                    <div className="import-details-summary-section-title-row">
                                        <div className="import-details-summary-section-title">Executive Summary</div>
                                        {analytics.form_classification && <span className="import-details-summary-executive-classification-badge">{analytics.form_classification}</span>}
                                    </div>
                                    <div className="import-details-summary-executive-text">{analytics.executive_summary}</div>
                                </div>
                                }

                                {/* FILE SYMBOL */}
                                <div className="import-details-summary-item">
                                    <div className="import-details-summary-section-title-row">
                                        <div className="import-details-summary-section-title">File Name</div>
                                    </div>
                                    <div className="import-details-summary-file-symbol">
                                        <div className="import-details-summary-file-symbol-icon">
                                            <TbCsv/>
                                        </div>
                                        <div className="import-details-summary-file-symbol-filename" title={importData.original_filename}>{importData.original_filename}</div>
                                    </div>
                                    <div className="import-details-summary-actions">
                                        <span onClick={() => handleNavClick('responses')}>View responses</span>
                                        <span onClick={() => handleNavClick('analytics')}>View analytics</span>
                                    </div>
                                </div>
                            </div>

                            <div className="import-details-summary-container-inner">
                                {importData.analytics &&
                                <div className="import-details-summary-log">
                                    <div className="import-details-summary-section-title">Statistics</div>
                                    <div className="import-details-summary-log-item">
                                        <div>Analysis Completed...</div>
                                        <div>{format(parseISO(importData.analytics.last_updated), 'PPP')}</div>
                                    </div>
                                    {importData.analytics.form_classification &&
                                    <div className="import-details-summary-log-item">
                                        <div>Form Classification...</div>
                                        <div>{importData.analytics.form_classification}</div>
                                    </div>
                                    }
                                    <div className="import-details-summary-log-item">
                                        <div>Total Responses...</div>
                                        <div>{importData.responses.length}</div>
                                    </div>
                                    <div className="import-details-summary-log-item">
                                        <div>Total Questions...</div>
                                        <div>{importData.column_mapping.length}</div>
                                    </div>
                                    <div className="import-details-summary-log-item">
                                        <div>Total Insights...</div>
                                        <div>{importData.analytics.form_insights.length}</div>
                                    </div>
                                    <div className="import-details-summary-log-item">
                                        <div>Total Recommendations...</div>
                                        <div>{importData.analytics.form_recommendations.length}</div>
                                    </div>
                                    {importData.analytics.sentiment_score &&
                                    <div className="import-details-summary-log-item">
                                        <div>Sentiment Score...</div>
                                        <div>{importData.analytics.sentiment_score}%</div>
                                    </div>
                                    }
                                </div>
                                }
                            </div>

                        </div>
                        }
                    </div>
                );
            case 'analytics':
                let licenseAllowsAnalytics = true
                if (!organization) return <div></div>
                if (organization.subscription_benefits) {
                    if (!organization.subscription_benefits.analytics) {
                        licenseAllowsAnalytics = false
                    }
                }
                return (
                    <FormBuilderAnalytics
                        formState={state}
                        analytics={analytics}
                        responses={responses}
                        licenseAllowsAnalytics={licenseAllowsAnalytics}
                        organizationId={organizationId}
                        showResponseRate={false}
                    />
                );
            case 'responses':
                return (
                    <FormResponseView 
                        responses={responses} 
                        formName={title} 
                    />
                );
            default:
                handleNavClick('main');
                return null;
        }
    };
    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);
    if (isLoading) {
        return (
            <div className="import-details">
                <div className="isLoading">Loading import details...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="import-details">
                <div className="error-message">{error}</div>
                <button 
                    onClick={() => navigate(`/o/${organization._id}/imports`)} 
                    className="back-button"
                >
                    <IoArrowBack /> Back to Imports
                </button>
            </div>
        );
    }

    return (
        <div className="new-form-container">
            <header className="new-form-design-header">
                <div className="new-form-design-header-left">
                    <div className="new-form-design-back">
                        <FaArrowLeft
                            className="new-form-design-back-icon"
                            onClick={handleBackClick}
                        />
                    </div>
                    <div className="new-form-design-status">
                        <span className={`new-form-design-status-value ${importData.status === 'draft' ? 'draft' : 'published'}`}>
                            {importData.status === "active" ? "Published" : importData.status}
                        </span>
                    </div>
                </div>

                <div className="new-form-design-header-right">
                    <div className="new-form-design-save-state">
                    </div>
                    {/* <div className="icon-button-container" ref={settingsMenuRef}>
                        <div className="icon-button">
                            <IoSettingsSharp />
                        </div>
                        {renderSettingsMenu()}
                    </div> */}
                </div>
            </header>
            <div className="new-form-design-title-container">

                <h1 className="new-form-design-title">
                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        onKeyDown={handleKeyDown}
                        className="new-form-design-title-input"
                    />
                ) : (
                    <h1 onClick={handleTitleClick} className="new-form-design-title">
                        {title}
                    </h1>
                )}
                </h1>
                <div className="form-builder-navigation-bar">
                    {navItems.map((item) => (
                        <span onClick={() => handleNavClick(item.id)} key={item.id} className={`${state.selectedNav === item.id ? 'selected' : ''}`}>{item.title}</span>
                    ))}
                </div>
            </div>
            <div className="new-form-content-wrapper">
                <main className="new-form-content">
                    {renderContent()}
                </main>
            </div>
        </div>
    );
} 
import React from 'react';
import { IoClose } from "react-icons/io5";
import './ImportWizardExampleTable.css';

const ImportWizardExampleTable = ({ data, onClose }) => {
    if (!data || data.length === 0) return null;

    const headers = data[0];
    const rows = data.slice(1);

    return (
        <div className="import-wizard-example-table-backdrop" onClick={onClose}>
            <div className="import-wizard-example-table-modal" onClick={e => e.stopPropagation()}>
                <div className="import-wizard-example-table-header">
                    <h2>Example Survey Data Format</h2>
                    <button 
                        className="import-wizard-example-table-close" 
                        onClick={onClose}
                        aria-label="Close example"
                    >
                        <IoClose />
                    </button>
                </div>
                <div className="import-wizard-example-table-description">
                    Your spreadsheet should follow this format with questions as column headers and responses in rows below.
                </div>
                <div className="import-wizard-example-table-container">
                    <table className="import-wizard-example-table">
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ImportWizardExampleTable; 
import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import contentfulClient from '../../helpers/ContentfulApi';
import './Blog.css'
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../helpers/Helpers'
import Footer from '../footer/Footer';
import { FaHashtag, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdOutlineClear } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";

const POSTS_PER_PAGE = 10;

export default function BlogLanding() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalPosts, setTotalPosts] = useState(0)
    const [filter, setFilter] = useState("all")
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState(null)
    const [searchPage, setSearchPage] = useState(1);
    const SEARCH_RESULTS_PER_PAGE = 10;

    const currentPage = parseInt(searchParams.get('page') || '1', 10);

    const handleSearch = async (query) => {
        if (!query.trim()) {
            setSearchResults(null);
            return;
        }

        try {
            const response = await contentfulClient.getEntries({
                content_type: "blogPost",
                select: ['fields.blogTitle', 'fields.tagline', 'fields.published', 'fields.contentCategories', 'fields.urlSlug'],
                'fields.blogTitle[match]': query.trim(),
                'fields.tagline[match]': query.trim(),
                order: "-sys.createdAt"
            });

            setSearchResults({
                items: response.items.map(item => item.fields),
                total: response.total
            });
        } catch (error) {
            console.error('Error searching blog posts:', error);
            setSearchResults({ items: [], total: 0 });
        }
    };

    const clearSearch = () => {
        setSearchQuery("");
        setSearchResults(null);
    };

    const updatePosts = async (page = 1) => {
        try {
            setIsLoading(true)
            const skip = (page - 1) * POSTS_PER_PAGE;
            
            const response = await contentfulClient.getEntries({
                content_type: "blogPost",
                order: "-sys.createdAt",
                limit: POSTS_PER_PAGE,
                skip: skip
            });

            setPosts(response.items.map(item => item.fields))
            setTotalPosts(response.total)
        } catch (error) {
            console.error('Error fetching blog posts:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const getPaginatedSearchResults = () => {
        if (!searchResults) return [];
        const start = (searchPage - 1) * SEARCH_RESULTS_PER_PAGE;
        const end = start + SEARCH_RESULTS_PER_PAGE;
        return searchResults.items.slice(start, end);
    };

    const totalSearchPages = searchResults ? Math.ceil(searchResults.items.length / SEARCH_RESULTS_PER_PAGE) : 0;

    const renderSearchResults = () => {
        if (!searchResults) return null;

        if (searchResults.items.length === 0) {
            return (
                <div className="blog-landing-search-empty">
                    <h3>No posts found</h3>
                    <p>We couldn't find any posts matching "{searchQuery}"</p>
                    <div className="blog-landing-search-actions">
                        <button 
                            onClick={clearSearch}
                            className="blog-landing-search-action-link"
                        >
                            Clear Search
                        </button>
                        <button 
                            onClick={() => navigate('/blog')}
                            className="blog-landing-search-action-link"
                        >
                            View All Posts
                        </button>
                    </div>
                </div>
            );
        }

        const paginatedResults = getPaginatedSearchResults();

        return (
            <div className="blog-landing-search-results">
                <div className="blog-landing-search-results-header">
                    <div className="blog-landing-search-results-header-left">
                        <span>{searchResults.items.length} results found</span>
                        <button 
                            onClick={clearSearch}
                            className="blog-landing-search-clear"
                        >
                            <MdOutlineClear /> Clear Search
                        </button>
                    </div>
                    {totalSearchPages > 1 && (
                        <div className="blog-landing-search-pagination">
                            <button 
                                onClick={() => setSearchPage(prev => Math.max(1, prev - 1))}
                                disabled={searchPage === 1}
                                className="blog-landing-search-pagination-button"
                            >
                                <FaArrowLeft />
                            </button>
                            <span>{searchPage} / {totalSearchPages}</span>
                            <button 
                                onClick={() => setSearchPage(prev => Math.min(totalSearchPages, prev + 1))}
                                disabled={searchPage === totalSearchPages}
                                className="blog-landing-search-pagination-button"
                            >
                                <FaArrowRight />
                            </button>
                        </div>
                    )}
                </div>
                <table className="blog-landing-search-table">
                    <tbody>
                        {paginatedResults.map((post) => (
                            <tr key={post.urlSlug}>
                                <td>
                                    <a href={`/blog/${post.urlSlug}`}>
                                        {post.blogTitle}
                                    </a>
                                </td>
                                <td>{formatDate(post.published)}</td>
                                <td>{post.contentCategories[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const totalPages = Math.ceil(totalPosts / POSTS_PER_PAGE);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setSearchParams({ page: newPage.toString() });
            window.scrollTo(0, 0);
        }
    }

    const updateMeta = () => {
        try {
            if (!posts || posts.length === 0) return

            // JSON-LD Data
            const itemListElement = posts.map((post, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://www.tetherform.com/blog/${post.urlSlug}`
            }));
            
            updateSchemaOrgJSONLD({
                "@context": "http://schema.org",
                "@type": "ItemList",
                "itemListElement": itemListElement
            })

            // Meta
            let title = currentPage === 1 
                ? "Blog | Tetherform" 
                : `Blog Page ${currentPage} | Tetherform`;
            let metaTags = [
                { name: 'description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
                { name: 'og:url', content: `https://tetherform.com/blog${currentPage > 1 ? `?page=${currentPage}` : ''}` },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:description', content: 'Survey tool that helps your organization engage and retain members more effectively' },
            ]
            updateHeadTags(title, metaTags)
        } catch (error) {
            console.error('Error updating meta tags:', error)
        }
    }

    useEffect(() => {
        updatePosts(currentPage)
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [currentPage])

    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [posts, currentPage])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const renderBlogPosts = (posts) => {
        if (posts.length === 0) return null;
        
        // Create sections of 5 posts
        const sections = [];
        for (let i = 0; i < posts.length; i += 5) {
            sections.push(posts.slice(i, i + 5));
        }
        
        return (
            <div className="blog-landing-sections">
                {sections.map((section, sectionIndex) => {
                    if (section.length === 5) {
                        // Full section layout
                        return (
                            <section key={sectionIndex} className="blog-landing-section">
                                <div className="blog-landing-top-row">
                                    <article className="blog-landing-featured-post">
                                        {section[0].heroImage && (
                                            <div className="blog-landing-list-content-image">
                                                <img src={section[0].heroImage.fields.file.url} alt={section[0].heroImage.fields.description} />
                                            </div>
                                        )}
                                        <div className="blog-landing-list-content-text">
                                            <div className="blog-landing-list-content-categories">
                                                {section[0].contentCategories.map((categoryName) => (
                                                    <span key={categoryName}><FaHashtag/>{categoryName}</span>
                                                ))}
                                            </div>
                                            <a 
                                                href={`/blog/${section[0].urlSlug}`}
                                                className="blog-landing-list-content-title"
                                            >
                                                <h2>{section[0].blogTitle}</h2>
                                            </a>
                                            <div className="blog-landing-list-content-date">
                                                {formatDate(section[0].published)}
                                            </div>
                                            <div className="blog-landing-list-content-tagline">{section[0].tagline}</div>
                                        </div>
                                    </article>
                                    <div className="blog-landing-side-posts">
                                        {section.slice(1, 3).map((post) => (
                                            <article key={post.urlSlug} className="blog-landing-side-post">
                                                <div className="blog-landing-side-post-categories">
                                                    {post.contentCategories.slice(0, 1).map((categoryName) => (
                                                        <span key={categoryName}><FaHashtag/>{categoryName}</span>
                                                    ))}
                                                </div>
                                                <a 
                                                    href={`/blog/${post.urlSlug}`}
                                                    className="blog-landing-side-post-title"
                                                >
                                                    <h3>{post.blogTitle}</h3>
                                                </a>
                                                <div className="blog-landing-side-post-date">
                                                    {formatDate(post.published)}
                                                </div>
                                                <div className="blog-landing-side-post-tagline">{post.tagline}</div>
                                            </article>
                                        ))}
                                    </div>
                                </div>
                                <div className="blog-landing-bottom-row">
                                    {section.slice(3, 5).map((post) => (
                                        <article key={post.urlSlug} className="blog-landing-list-content">
                                            {post.heroImage && (
                                                <a 
                                                    href={`/blog/${post.urlSlug}`}
                                                    className="blog-landing-list-content-image"
                                                >
                                                    <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                                                </a>
                                            )}
                                            <div className="blog-landing-list-content-text">
                                                <div className="blog-landing-list-content-categories">
                                                    {post.contentCategories.map((categoryName) => (
                                                        <span key={categoryName}><FaHashtag/>{categoryName}</span>
                                                    ))}
                                                </div>
                                                <a 
                                                    href={`/blog/${post.urlSlug}`}
                                                    className="blog-landing-list-content-title"
                                                >
                                                    <h2>{post.blogTitle}</h2>
                                                </a>
                                                <div className="blog-landing-list-content-date">
                                                    {formatDate(post.published)}
                                                </div>
                                                <div className="blog-landing-list-content-tagline">{post.tagline}</div>
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            </section>
                        );
                    } else {
                        // Partial section layout (less than 5 posts)
                        return (
                            <div key={sectionIndex} className="blog-landing-partial-section">
                                {section.map((post, index) => (
                                    <article key={post.urlSlug} className="blog-landing-list-content">
                                        {post.heroImage && (
                                            <a 
                                                href={`/blog/${post.urlSlug}`}
                                                className="blog-landing-list-content-image"
                                            >
                                                <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                                            </a>
                                        )}
                                        <div className="blog-landing-list-content-text">
                                            <div className="blog-landing-list-content-categories">
                                                {post.contentCategories.map((categoryName) => (
                                                    <span key={categoryName}><FaHashtag/>{categoryName}</span>
                                                ))}
                                            </div>
                                            <a 
                                                href={`/blog/${post.urlSlug}`}
                                                className="blog-landing-list-content-title"
                                            >
                                                <h2>{post.blogTitle}</h2>
                                            </a>
                                            <div className="blog-landing-list-content-date">
                                                {formatDate(post.published)}
                                            </div>
                                            <div className="blog-landing-list-content-tagline">{post.tagline}</div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <div className="blog">
            <div className="blog-landing-heading">
                <div className="blog-landing-heading-inner">
                    <h1>Modern Digital Library</h1>
                    <p>A blog dedicated to the profession of improvement</p>
                    <div className="blog-landing-search">
                        <div className="blog-landing-search-row">
                            <div className="blog-landing-search-input-wrapper">
                                <IoIosSearch className="blog-landing-search-icon" />
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                        if (!e.target.value.trim()) {
                                            clearSearch();
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch(searchQuery);
                                        }
                                    }}
                                    className="blog-landing-search-input"
                                    placeholder="Find something to learn..."
                                />
                            </div>
                            {!searchResults && (
                                <div className="blog-landing-search-navigation">
                                    <button 
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1 || isLoading}
                                        className="blog-landing-search-nav-button"
                                        aria-label="Previous page"
                                    >
                                        <FaArrowLeft />
                                    </button>
                                    <button 
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage >= totalPages || isLoading}
                                        className="blog-landing-search-nav-button"
                                        aria-label="Next page"
                                    >
                                        <FaArrowRight />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="blog-landing-header-navs">
                        <span className={filter === "all" && "active"} onClick={() => setFilter("all")}>All</span>
                        <span className={filter === "event" && "active"} onClick={() => setFilter("event")}>Event Planning</span>
                        <span className={filter === "association" && "active"} onClick={() => setFilter("association")}>Professional Association</span>
                        <span className={filter === "surveys" && "active"} onClick={() => setFilter("surveys")}>Survey Tips & Tricks</span>
                    </div>
                </div>
            </div>
            <div className="blog-landing-list">
                {searchResults ? (
                    renderSearchResults()
                ) : (
                    <>
                        {renderBlogPosts(posts)}
                        <div className="blog-landing-pagination">
                            <button 
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1 || isLoading}
                                className="blog-landing-pagination-button"
                            >
                                <FaArrowLeft /> Previous
                            </button>
                            <span className="blog-landing-pagination-info">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button 
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage >= totalPages || isLoading}
                                className="blog-landing-pagination-button"
                            >
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </>
                )}
            </div>
            <Footer/>
        </div>
    );
}

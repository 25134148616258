exports.trial_plans = [
    {
        tetherform_plan_ref: "plan_24q4_starter",
        plan_description: "Great for small organizations that want to run smarter surveys with some gadgets and gizmos to make life easier.",
        display_name: "Starter",
        monthly_price: 29,
        monthly_responses: 500,
        features: ["unlimited_forms", "template_library", "custom_branding", "advanced_form_logic"]
    },
    {
        tetherform_plan_ref: "plan_24q4_standard",
        plan_description: "Take surveys to the next level with automated analytics reporting you'll be excited to share with the rest of the team.",
        display_name: "Standard",
        monthly_price: 79,
        monthly_responses: 1000,
        features: ["unlimited_forms", "template_library", "custom_branding", "unlimited_seats", "advanced_form_logic", "analytics"]
    },
    {
        tetherform_plan_ref: "plan_24q4_pro",
        plan_description: "For organizations who want survey recommendations so good they'll ignore us and take all the credit.",
        display_name: "Pro",
        monthly_price: 149,
        monthly_responses: 2000,
        features: ["unlimited_forms", "template_library", "custom_branding", "unlimited_seats", "advanced_form_logic", "priority_support", "analytics"]
    },
    {
        tetherform_plan_ref: "plan_24q4_premium",
        plan_description: "For the big, busy organizations that ask a lot of questions. This is everything Tetherform has to offer.",
        display_name: "Premium",
        monthly_price: 349,
        monthly_responses: 5000,
        features: ["unlimited_forms", "template_library", "custom_branding", "unlimited_seats", "advanced_form_logic", "priority_support", "personal_onboarding", "analytics"]
    }
]

exports.features = [
    { 
        key: "unlimited_forms", 
        display_name: "Unlimited Forms", 
        description: "Create and manage unlimited surveys and forms" 
    },
    { 
        key: "template_library", 
        display_name: "Template Library", 
        description: "Access pre-built templates for common survey types" 
    },
    { 
        key: "custom_branding", 
        display_name: "Custom Branding", 
        description: "Add your logo and customize colors to match your brand" 
    },
    { 
        key: "advanced_form_logic", 
        display_name: "Advanced Form Logic", 
        description: "Create dynamic surveys with conditional questions and branching logic" 
    },
    { 
        key: "analytics", 
        display_name: "Insights & Analytics Reporting", 
        description: "Get AI-powered insights and detailed analytics from your survey responses" 
    },
    { 
        key: "unlimited_seats", 
        display_name: "Unlimited Seats", 
        description: "Add as many team members as you need at no additional cost" 
    },
    { 
        key: "priority_support", 
        display_name: "Priority Support", 
        description: "Get faster response times and dedicated support channels" 
    },
    { 
        key: "personal_onboarding", 
        display_name: "Personal Onboarding", 
        description: "Get dedicated support to help set up and optimize your surveys" 
    },
];
import React from 'react';
import { useLocation } from 'react-router-dom';
import AuthenticatedSidebar from '../components/authenticated_sidebar/AuthenticatedSidebar';

const hideLayoutPaths = [
    '/f', // form preview
    '/pr', // form preview
];

export default function AuthenticatedLayout({ children }) {
    const location = useLocation();
    const shouldHideLayout = hideLayoutPaths.some(path => location.pathname.startsWith(path)) || 
        // Hide for form editing but not the forms list
        (location.pathname.includes('/forms/') && !location.pathname.endsWith('/forms')) ||

        (location.pathname.includes('/imports/') && !location.pathname.endsWith('/imports') && !location.pathname.endsWith('/new'))

    if (shouldHideLayout) {
        return <>{children}</>;
    }

    return (
        <div className="authenticated-layout">
            <AuthenticatedSidebar />
            <div className="authenticated-layout-content">
                {children}
            </div>
        </div>
    );
} 
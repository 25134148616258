import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, TimeScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './FormBuilderAnalytics.css';
import './FormBuilderOptions.css';
import StackedBarChart from '../gizmos/StackedBarChart';
import WeeklyHeatmap from '../gizmos/WeeklyHeatmap';
import { getAccountManagementUrl } from '../../api';

ChartJS.register(TimeScale, LinearScale, BarElement, Title, Tooltip, Legend);
const FormBuilderAnalytics = ({ formState, analytics, responses, licenseAllowsAnalytics, organizationId, showResponseRate=true }) => {
    console.log(analytics)
    const [insights, setInsights] = useState(() => { return []})
    const [recommendations, setRecommendations] = useState(() => { return []})
    const [isManagingPlan, setIsManagingPlan] = useState(() => { return false })
    const initRecommendationsAndInsights = () => {
        try {
            if (!analytics) return
            if (analytics.key_findings) setInsights(analytics.key_findings)
            if (analytics.form_insights) {
                if (analytics.form_insights.length > 0) setInsights(analytics.form_insights)
            }
            if (analytics.form_recommendations) setRecommendations(analytics.form_recommendations)
        } catch (error) {
            return false
        }
    }
    const handleManagePlan = async () => {
        try {
            if (!organizationId) return
            if (isManagingPlan) return
            setIsManagingPlan(true)
            const response = await getAccountManagementUrl(organizationId)
            if (response.data && response.data.checkoutSessionUrl) {
                window.location.href = response.data.checkoutSessionUrl
                setIsManagingPlan(false)
            }
        } catch (err) {
            return setIsManagingPlan(false)
        } finally {
            setIsManagingPlan(false)
        }
    }
    const hasExperienceBreakdown = () => {
        try {
            if (!analytics) return false
            if (!analytics.experience_breakdown) return false
            let mobile = 0
            let desktop = 0
            if (analytics.experience_breakdown.mobile) mobile = analytics.experience_breakdown.mobile
            if (!analytics.experience_breakdown.desktop) desktop = analytics.experience_breakdown.desktop
            if (mobile + desktop > 0) return true
            return false
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        initRecommendationsAndInsights()
    // eslint-disable-next-line
    }, [analytics])

    if (!analytics && licenseAllowsAnalytics) {
        return (
            <div className="form-builder-analytics-container">
                <p className="form-builder-analytics-message" style={{marginTop: "50px", marginLeft: "auto", marginRight: "auto"}}>Analytics are not ready yet. Come back later.</p>
            </div>
        );
    }

    if (!licenseAllowsAnalytics) {
        return (
            <div className="form-builder-analytics-container">
                <div className="form-builder-analytics-upgrade">
                    <h3>Unrivaled Analytics</h3>
                    <p className="form-builder-analytics-message">Use the power of our advanced insights and analytics reports to make faster, better decisions as a team based on form responses. Available to organizations with a Standard plan or higher.</p>
                    {organizationId && <div className="form-builder-analytics-upgrade-button" onClick={handleManagePlan}>Manage Plan</div>}
                </div>
            </div>
        );
    }
    let responseTimestamps = []
    if (responses) {
        responseTimestamps = responses.map(r => r.submitted_at)
    }
    const formatSecondsStringToString = (rawTotalSeconds) => {
        const totalSeconds = Math.floor(rawTotalSeconds);
        if (isNaN(totalSeconds)) return false
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
    }
    const getTimeString = () => {
        try {
            if (!analytics) return false;
            if (analytics.average_completion_time_seconds < 0) return false
            if (isNaN(analytics.average_completion_time_seconds)) return false
            const formattedTime = formatSecondsStringToString(analytics.average_completion_time_seconds)
            return formattedTime;
        } catch (error) {
            return false;
        }
    }
    const getAverageCompletionTimeDisplayString = () => {        
        const timeString = getTimeString();
        if (!timeString) return <></>;

        return (
            <div className="form-builder-analytics-metric">
                <span className="form-builder-analytics-metric-number">{timeString}</span>
                <span className="form-builder-analytics-metric-label">Average Completion Time</span>
            </div>
        )
    }
    const estimateSurveyTime = (defaultTime = 15) => {
        try {
            let survey = formState.structure
            // Define average time estimates (in seconds) for each question type
            const timeEstimates = {
                rating: 10,             // Average 10 seconds for a rating question
                "select-multiple": 20,  // Average 20 seconds for select-multiple
                "multiple-choice": 10,  // Average 10 seconds for multiple-choice
                text: 45                // Average 45 seconds for text input
            };
            
            // Calculate the total time
            let totalTime = 0;
            
            survey.forEach((question) => {
                const questionType = question.type;
                const isRequired = question.required !== false; // Default to true if not specified
                let estimatedTime = timeEstimates[questionType] || defaultTime; // Use defaultTime if type is not in timeEstimates
            
                // Adjust time for optional questions (reduce slightly)
                if (!isRequired) {
                estimatedTime *= 0.8; // Optional questions may take 80% of the time
                }
            
                totalTime += estimatedTime;
            });
            
            // Add buffer time for instructions and transitions (20% of total time)
            const bufferTime = totalTime * 0.2;
            totalTime += bufferTime;
            
            return Math.round(totalTime); 
        } catch (error) {
            return false 
        }

      };
    return (
        <div className="form-builder-analytics-container">
            <div className="form-builder-analytics-header-metrics">
                {analytics.form_classification &&
                <div className="form-builder-analytics-metric">
                    <span className="form-builder-analytics-metric-number" style={{fontSize: "14px"}}>{analytics.form_classification}</span>
                    <span className="form-builder-analytics-metric-label">Form Classification</span>
                </div>
                }
                <div className="form-builder-analytics-metric">
                    <span className="form-builder-analytics-metric-number">{analytics.response_total}</span>
                    <span className="form-builder-analytics-metric-label">Responses</span>
                </div>
                {formState.structure &&
                <div className="form-builder-analytics-metric">
                    <span className="form-builder-analytics-metric-number">{formState.structure.length}</span>
                    <span className="form-builder-analytics-metric-label">Total Questions</span>
                </div>
                }
                {analytics.sentiment_score &&
                <div className="form-builder-analytics-metric">
                    <span className="form-builder-analytics-metric-number">{analytics.sentiment_score}%</span>
                    <span className="form-builder-analytics-metric-label">Sentiment Score</span>
                </div>
                }
                {getAverageCompletionTimeDisplayString()}
            </div>

            <div className="form-analytics-split-container">


                {/* LARGE MAIN PANEL */}
                <div className="form-analytics-split-container-column-large">
                    <div className="form-builder-analytics-key-findings">
                        <div className="form-builder-analytics-box">
                            <div className="form-builder-analytics-box-header">
                                <h3>Insights</h3>
                            </div>
                            <div className="form-builder-analytics-box-subheading">Key findings and patterns discovered from analyzing your form's responses.</div>
                            <div className="form-builder-analytics-findings-list">
                                {insights.map((finding, index) => (
                                    <div key={index} className="form-builder-analytics-finding-item">
                                        <div className="form-builder-analytics-finding-headline">{finding.insight_headline}</div>
                                        <div className="form-builder-analytics-finding-summary">{finding.insight_summary}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="form-builder-analytics-key-findings">
                        <div className="form-builder-analytics-box">
                            <div className="form-builder-analytics-box-header">
                                <h3>Recommendations</h3>
                            </div>
                            <div className="form-builder-analytics-box-subheading">Suggested actions you might want to consider based on the results of your form so far.</div>
                            <div className="form-builder-analytics-findings-list">
                                {recommendations.map((finding, index) => (
                                    <div key={index} className="form-builder-analytics-finding-item form-builder-analytics-finding-item-rec">
                                        <div className="form-builder-analytics-finding-headline">{finding.recommendation_title}</div>
                                        <div className="form-builder-analytics-finding-summary">{finding.recommendation_description}</div>
                                    </div>
                                ))}
                                {recommendations.length === 0 &&
                                <div style={{fontSize: "13px", textAlign: "center"}}>None</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* SMALL RIGHT SIDE */}
                <div className="form-analytics-split-container-column-small">

                    {/* EXECUTIVE SUMMARY */}
                    {analytics.executive_summary &&
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-box-header">
                            <h3>Executive Summary</h3>
                        </div>
                        <div className="form-builder-analytics-box-subheading">The impact on your organization</div>
                        <div className="form-builder-analytics-chart">
                            <p style={{fontSize: "14px", lineHeight: 1.6}}>{analytics.executive_summary}</p>
                        </div>
                    </div>
                    }

                    {/* RESPONSE RATE */}
                    {responseTimestamps.length > 0 && showResponseRate &&
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-box-header">
                            <h3>Response Rate</h3>
                        </div>
                        <div className="form-builder-analytics-box-subheading">When during the week, and at what time of day, people are submitting the form</div>
                        <div className="form-builder-analytics-chart">
                            <WeeklyHeatmap timeseriesData={responseTimestamps} />
                        </div>
                    </div>
                    }

                    {/* DEVICES */}
                    {hasExperienceBreakdown() &&
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-box-header">
                            <h3>Device</h3>
                        </div>
                        <div className="form-builder-analytics-box-subheading">What people are using to take the form</div>
                        <div className="form-builder-analytics-chart">
                            <StackedBarChart data={analytics.experience_breakdown} blacklist={["unknown"]} />
                        </div>
                    </div>
                    }

                    {formState.structure && 
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-box-header">
                            <h3>Completion Speed</h3>
                        </div>
                        <div className="form-builder-analytics-box-subheading">A comparison of how long it's taking people to get through the form based on an estimated length.</div>
                        <div className="form-builder-analytics-chart">
                            <div className="form-builder-analytics-key-value-strings">
                                <div>Total Questions</div>
                                <span>{formState.structure.length}</span>
                            </div>
                            <div className="form-builder-analytics-key-value-strings">
                                <div>Estimated Time to Complete</div>
                                <span>{formatSecondsStringToString(estimateSurveyTime())}</span>
                            </div>
                            {getTimeString() &&
                            <div className="form-builder-analytics-key-value-strings">
                                <div>Actual Time to Complete</div>
                                <span>{getTimeString()}</span>
                            </div>
                            }
                        </div>
                    </div>
                    }

                </div>
            </div>


            <div className="form-builder-analytics-padding"></div>
        </div>
    );
};
export default FormBuilderAnalytics;

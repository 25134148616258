import React from 'react'
import './Dashboard.css'
// import { useAuth } from '../../context/AuthContext'
import { useOrganization } from '../../context/OrganizationContext';
import Loader from '../loaders/Loader';

export default function Dashboard() {
    // const { user } = useAuth();
    const { organization, organizationIsLoading } = useOrganization()
    return (
        <div className="dashboard-container">
            {/* MAIN */}
            {organization && !organizationIsLoading &&
            <div className="dashboard">
                <div className="dashboard-main">
                    <h1>{organization.name}</h1>
                </div>
            </div>
            }


            {/* LOADING */}
            {organizationIsLoading &&
            <div style={{marginTop: "120px"}}>
                <Loader/>
            </div>
            }
        </div>
    )
}

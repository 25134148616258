import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import './FormResponseView.css';
import './FormBuilderOptions.css';
import FormResponseViewText from './form_response_charts/FormResponseViewText';
import OmittedAnswersView from './form_response_charts/OmittedAnswersView';
import { IoMdDownload } from "react-icons/io"
import { getChartColorByIndex, getChartColorWithOpacity } from '../../helpers/Helpers';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    ChartDataLabels,
    annotationPlugin,
    zoomPlugin
);

const FormResponseView = ({ responses, formName }) => {
    const processedData = useMemo(() => {
        if (!responses || responses.length === 0) {
            return [];
        }

        const questionMap = new Map();

        responses.forEach(response => {
            response.answers.forEach(answer => {
                if (!answer.valid) return;

                if (!questionMap.has(answer.questionId)) {
                    questionMap.set(answer.questionId, {
                        questionText: answer.question_text,
                        questionType: answer.question_type,
                        answers: []
                    });
                }

                questionMap.get(answer.questionId).answers.push(answer.answer);
            });
        });
        return Array.from(questionMap.entries());
    }, [responses]);
    if (responses.length === 0) {
        return (
            <div className="form-builder-analytics-container">
                <p className="form-builder-analytics-message">No responses yet. Come back later.</p>
            </div>
        );
    }
    const downloadCSV = () => {
        // Create an object to store all questions
        const questions = {};

        // Populate the questions object
        responses.forEach(response => {
            response.answers.forEach(answer => {
                if (!answer.valid) return;
                questions[answer.questionId] = answer.question_text;
            });
        });

        // Create the CSV content
        let csvContent = 'ID,Date,' + 
            Object.values(questions)
                .map((q) => `"${q.replace(/"/g, '""')}"`)
                .join(',') + '\n';

        // Add each row of responses
        responses.forEach(response => {
            const submittedDate = new Date(response.submitted_at).toISOString().split('T')[0]; // Format as yyyy-mm-dd
            let row = `"${response._id}","${submittedDate}",`;
            
            row += Object.keys(questions)
                .map(questionId => {
                    const answer = response.answers.find(a => a.questionId === questionId)?.answer;
                    if (answer === undefined || answer === null) {
                        return '""';
                    }
                    if (Array.isArray(answer)) {
                        return `"${answer.join(', ').replace(/"/g, '""')}"`;
                    }
                    if (typeof answer === 'string') {
                        return `"${answer.replace(/"/g, '""')}"`;
                    }
                    return `"${answer}"`;
                })
                .join(',');
            
            csvContent += row + '\n';
        });

        // Create and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            const date = new Date()
                .toISOString()
                .split('T')[0]
                .replace(/-/g, ' '); // Format: YYYY MM DD
            const sanitizedFormName = formName.replace(/[^a-z0-9]/gi, ' ').trim();
            const fileName = `${date} ${sanitizedFormName}.csv`;
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const renderChart = (questionId, questionData) => {
        const { questionType } = questionData;

        switch (questionType) {
            case 'text':
                return <FormResponseViewText key={questionId} questionId={questionId} questionData={questionData} />;
            case 'rating':
                const { questionText, answers } = questionData;
                const ratingCounts = answers.reduce((acc, rating) => {
                    acc[rating] = (acc[rating] || 0) + 1;
                    return acc;
                }, {});

                const totalResponses = Object.values(ratingCounts).reduce((sum, count) => sum + count, 0);
                const averageScore = (
                    Object.entries(ratingCounts).reduce((sum, [rating, count]) => sum + rating * count, 0) / totalResponses
                ).toFixed(1);

                // Ensure the x-axis starts at 1 and includes all integers up to the maximum rating
                const maxRating = Math.max(...Object.keys(ratingCounts).map(Number));
                const sortedRatings = Array.from({ length: maxRating }, (_, i) => (i + 1).toString());

                const maxResponse = Math.max(...Object.values(ratingCounts));
                const yAxisMax = Math.ceil((maxResponse) / 5) * 5;

                const chartData = {
                    labels: sortedRatings,
                    datasets: [
                        {
                            label: 'Total Responses',
                            data: sortedRatings.map((rating) => ratingCounts[rating] || 0),
                            borderColor: '#309C83',
                            backgroundColor: '#309C83',
                            tension: 0.3,
                            pointRadius: 4,
                            pointBackgroundColor: '#309C83',
                            fill: false,
                        },
                    ],
                };

                return (
                    <div key={questionId} className="form-response-view-chart" style={{ position: 'relative' }}>
                        <div className="form-response-view-chart-header">
                            <h3>{questionText}</h3>
                            <div className="form-response-view-chart-average-score">{averageScore}</div>
                        </div>
                        <div className="chart-container" style={{ height: '300px', position: 'relative' }}>
                            <Line
                                data={chartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            type: 'linear',
                                            min: 1,
                                            max: maxRating,
                                            ticks: {
                                                stepSize: 1,
                                                precision: 0,
                                            },
                                            title: {
                                                display: true,
                                                text: 'Rating',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold',
                                                },
                                            },
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            max: yAxisMax,
                                            ticks: {
                                                stepSize: 1,
                                                precision: 0,
                                            },
                                            title: {
                                                display: true,
                                                text: 'Total Responses',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold',
                                                },
                                            },
                                            grid: {
                                                color: 'rgba(255, 255, 255, 0.441)',
                                                lineWidth: 0.5,
                                            },
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: (context) => {
                                                    const value = context.parsed.y || 0;
                                                    const percentage = (
                                                        (value / totalResponses) *
                                                        100
                                                    ).toFixed(1);
                                                    return `${percentage}%`;
                                                },
                                            },
                                        },
                                        datalabels: {
                                            anchor: 'end',
                                            align: 'start',
                                            formatter: (value) => value,
                                            color: 'transparent',
                                            font: {
                                                weight: 'bold',
                                                size: 12,
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                );
            case 'multiple-choice':
            case 'select-multiple':
                const { questionText: mcQuestionText, answers: mcAnswers } = questionData;
                const choiceCounts = mcAnswers.flat().reduce((acc, choice) => {
                    acc[choice] = (acc[choice] || 0) + 1;
                    return acc;
                }, {});

                // Track omitted answers
                let omittedAnswers = [];
                
                // Filter and process the data based on number of options
                let processedCounts = { ...choiceCounts };
                const hasOther = Object.keys(processedCounts).includes("Other");
                
                if (Object.keys(processedCounts).length > 8 && hasOther) {
                    // Collect answers with only 1 response (except "Other")
                    omittedAnswers = Object.entries(processedCounts)
                        .filter(([key, count]) => key !== "Other" && count <= 1)
                        .map(([key]) => key);

                    // Filter out items with less than 1 response, but keep "Other"
                    processedCounts = Object.entries(processedCounts)
                        .filter(([key, count]) => key === "Other" || count > 1)
                        .reduce((acc, [key, value]) => {
                            acc[key] = value;
                            return acc;
                        }, {});
                }

                const labels = Object.keys(processedCounts);
                const isHorizontal = true;

                // Calculate the axis maximum value based on the rule of thumb
                const values = Object.values(processedCounts);
                const maxValue = Math.max(...values);

                // Calculate appropriate step size for grid lines
                let stepSize;
                if (maxValue <= 10) {
                    stepSize = 1;
                } else if (maxValue <= 50) {
                    stepSize = 5;
                } else if (maxValue <= 100) {
                    stepSize = 10;
                } else if (maxValue <= 500) {
                    stepSize = 50;
                } else if (maxValue <= 1000) {
                    stepSize = 100;
                } else {
                    stepSize = Math.pow(10, Math.floor(Math.log10(maxValue / 10)));
                }

                // Round up max to nearest step
                const axisMax = Math.ceil(maxValue / stepSize) * stepSize;

                const barChartData = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Responses',
                            data: values,
                            backgroundColor: labels.map((_, index) => getChartColorWithOpacity(index)),
                            borderColor: labels.map((_, index) => getChartColorByIndex(index)),
                            borderWidth: 1,
                            borderRadius: 4
                        },
                    ],
                };

                const height = values.length * 50

                return (
                    <>
                        <div key={questionId} className="form-response-view-chart">
                            <h3>{mcQuestionText}</h3>
                            <div className="chart-container">
                                <Bar
                                    data={barChartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        indexAxis: isHorizontal ? 'y' : 'x',
                                        scales: {
                                            [isHorizontal ? 'x' : 'y']: {
                                                beginAtZero: true,
                                                max: axisMax,
                                                ticks: {
                                                    stepSize: stepSize,
                                                    precision: 0,
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Total Responses',
                                                    font: {
                                                        size: 14,
                                                        weight: 'bold',
                                                    },
                                                },
                                                grid: {
                                                    display: true,
                                                    color: 'rgba(255, 255, 255, 0.1)',
                                                    lineWidth: 0.5
                                                },
                                            },
                                            [isHorizontal ? 'y' : 'x']: {
                                                beginAtZero: true,
                                                ticks: {
                                                    display: false,
                                                },
                                                title: {
                                                    display: false,
                                                },
                                                grid: {
                                                    display: false
                                                },
                                            },
                                        },
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            datalabels: {
                                                anchor: isHorizontal ? 'start' : 'center',
                                                align: isHorizontal ? 'right' : 'center',
                                                clamp: true,
                                                formatter: (value, context) => {
                                                    const label = labels[context.dataIndex];
                                                    if (label.length > 80) {
                                                        return label.substring(0, 80) + '...';
                                                    }
                                                    return label;
                                                },
                                                color: '#fff',
                                                font: {
                                                    weight: 400,
                                                    size: 11,
                                                    lineHeight: 1.2
                                                },
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                borderRadius: 4,
                                                padding: {
                                                    top: 2,
                                                    right: 6,
                                                    bottom: 2,
                                                    left: 6
                                                },
                                                textAlign: 'left'
                                            },
                                        },
                                    }}
                                    height={height}
                                />
                            </div>
                            {omittedAnswers.length > 0 && (
                                <OmittedAnswersView omittedAnswers={omittedAnswers} />
                            )}
                        </div>

                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="form-response-view-container">
            <div className="form-builder-design-options-header-row">
                <h2>Responses</h2>
                <div className="form-builder-design-options-header-text-button" onClick={downloadCSV}>Download  <IoMdDownload style={{fontSize: "1.1rem"}}/></div>
            </div>
            <div className="form-response-view-grid">
                <div className="form-response-view-column">
                    {processedData
                        .slice(0, Math.ceil(processedData.length / 2))
                        .map(([questionId, questionData]) =>
                            renderChart(questionId, questionData)
                        )}
                </div>
                <div className="form-response-view-column">
                    {processedData
                        .slice(Math.ceil(processedData.length / 2))
                        .map(([questionId, questionData]) =>
                            renderChart(questionId, questionData)
                        )}
                </div>
            </div>
        </div>
    );
};

export default FormResponseView;

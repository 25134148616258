import React, { useRef } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import { IoCopyOutline, IoOpenOutline } from "react-icons/io5";
import { saveAs } from 'file-saver';
import './FormSharing.css'
import './FormBuilderOptions.css';

export default function FormSharing({ formState, organizationId, formId, openPreview, openLiveForm }) {
    const qrRef = useRef(null);
    let baseUrl = "https://tetherform.com"
    if (process.env.NODE_ENV === "development") baseUrl = "http://localhost:3000"
    const link = `${baseUrl}/f/${formId}`
    let primaryColor = "#309C83"
    if (formState.design_primary_color) primaryColor = formState.design_primary_color

    const downloadQRCode = (svg, colorVariant = '') => {
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        let fileName = formState?.title ? `${formState.title} QR Code${colorVariant}.png` : `tetherform${colorVariant}.png`;
        
        img.onload = () => {
            canvas.width = 1024;
            canvas.height = 1024;
            ctx.drawImage(img, 0, 0, 1024, 1024);
            canvas.toBlob((blob) => {
                saveAs(blob, fileName);
            });
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    };

    const copyLinkToClipboard = (text) => {
        return navigator.clipboard.writeText(text)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }

    const copyQRCodeToClipboard = (svg) => {
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        
        img.onload = () => {
            canvas.width = 1024;
            canvas.height = 1024;
            ctx.drawImage(img, 0, 0, 1024, 1024);
            canvas.toBlob(async (blob) => {
                try {
                    await navigator.clipboard.write([
                        new ClipboardItem({
                            'image/png': blob
                        })
                    ]);
                    console.log('QR code copied to clipboard');
                } catch (err) {
                    console.error('Failed to copy QR code: ', err);
                }
            }, 'image/png');
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    };

    return (
        <div className="form-builder-sharing">
            <div className="form-builder-design-options-header-row"><h2>Sharing & Distribution</h2></div>

            {formState.status === "active" &&
                <div className="form-builder-design-option">
                    <div className="form-builder-design-option-information">
                        <h4>Published Form</h4>
                        <p>Share this link to give users access to the active form</p>
                    </div>
                    <div className="form-builder-design-option-setting">
                        <div className="form-builder-sharing-link-text-container">
                            <div className="form-builder-sharing-link-text">{link}</div>
                            <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={openLiveForm}>
                                <IoOpenOutline />
                                Open
                            </div>
                            <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={() => copyLinkToClipboard(link)}>
                                <IoCopyOutline />
                                Copy
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Preview Draft</h4>
                    <p>Share this link to give users access to the preview draft of the form</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="form-builder-sharing-link-text-container">
                        <div className="form-builder-sharing-link-text">{baseUrl}/pr/{organizationId}/{formId}</div>
                        <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={openPreview}>
                            <IoOpenOutline />
                            Open
                        </div>
                        <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={() => copyLinkToClipboard(`${baseUrl}/pr/${organizationId}/${formId}`)}>
                            <IoCopyOutline />
                            Copy
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>QR Code</h4>
                    <p>Use or download a QR code for rapid access and sharing</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div style={{display: "flex", justifyContent: "flex-start", gap: "20px"}}>
                        <div className="qr-code-container">
                            <QRCodeSVG value={link} size={200} level="H" includeMargin={true} ref={qrRef} fgColor={primaryColor} bgColor="transparent" />
                            <div className="qr-code-actions">
                                <div className="qr-download-button" onClick={() => downloadQRCode(qrRef.current, '')}>
                                    Download
                                </div>
                                <div className="qr-download-button" onClick={() => copyQRCodeToClipboard(qrRef.current)}>
                                    Copy
                                </div>
                            </div>
                        </div>
                        <div className="qr-code-container">
                            <QRCodeSVG value={link} size={200} level="H" includeMargin={true} fgColor="#000000" bgColor="transparent" />
                            <div className="qr-code-actions">
                                <div className="qr-download-button" onClick={(e) => downloadQRCode(e.currentTarget.parentElement.parentElement.querySelector('svg'), ' Black')}>
                                    Download
                                </div>
                                <div className="qr-download-button" onClick={(e) => copyQRCodeToClipboard(e.currentTarget.parentElement.parentElement.querySelector('svg'))}>
                                    Copy
                                </div>
                            </div>
                        </div>
                        <div className="qr-code-container">
                            <QRCodeSVG value={link} size={200} level="H" includeMargin={true} fgColor="#FFFFFF" bgColor="transparent" />
                            <div className="qr-code-actions">
                                <div className="qr-download-button" onClick={(e) => downloadQRCode(e.currentTarget.parentElement.parentElement.querySelector('svg'), ' White')}>
                                    Download
                                </div>
                                <div className="qr-download-button" onClick={(e) => copyQRCodeToClipboard(e.currentTarget.parentElement.parentElement.querySelector('svg'))}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Embed</h4>
                    <p>Render the form directly on your website</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="form-builder-design-option-setting-coming-soon">Coming Soon</div>
                </div>
            </div>

        </div>
    )
}

import React from 'react'
import { useOrganization } from '../../context/OrganizationContext'
import AuthenticatedSignUp from './AuthenticatedSignUp'
import { useNavigate } from 'react-router-dom'
import { LuMoveLeft } from "react-icons/lu";

export default function AuthenticatedSignUpPage() {
    const navigate = useNavigate()
    const { organization, loading, isActiveLicense } = useOrganization()
    if (loading || !organization) {
        return (
            <div className="authenticated-signup-page-container">
                <div className="authenticated-signup-page-header">
                    <span>
                        <LuMoveLeft/>
                        Go back
                    </span>
                </div>
                Loading available plans...
            </div>
        )
    }
    if (!loading && isActiveLicense) {
        return navigate("/")
    }
    return (
        <div className="authenticated-signup-page-container">
            <div className="authenticated-signup-page-header">
                <span onClick={() => navigate(-1)}>
                    <LuMoveLeft/>
                    Go back
                </span>
            </div>
            <AuthenticatedSignUp />
        </div>
    )
}

import React from 'react';
import { trial_plans, features } from '../../config/TRIAL_PLANS';
import { formatUSDCurrency } from '../../helpers/Helpers';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import '../home/Pricing.css';
import './AuthenticatedSignUp.css';
import { updateOrganizationPlan } from '../../api';
import { useOrganization } from '../../context/OrganizationContext';

export default function AuthenticatedSignUp() {
    const { organization } = useOrganization()
    const onPlanSelect = (planRef) => {
        if (!organization) return
        if (!organization._id) return 
        updateOrganizationPlan(organization._id, planRef)
        .then((res) => {
            if (res.data) {
                if (res.data.url) {
                    window.location.href = res.data.url
                }
            }
        })
        .catch((err) => {
            return
        })
    }
    return (
        <div className="authenticated-signup-container">
            <div className="authenticated-signup-header">
                <h2>Select a plan to get started</h2>
            </div>

            <div className="plans-and-pricing-cards">
                {trial_plans.map((plan) => (
                    <div 
                        key={plan.tetherform_plan_ref} 
                        className={`plans-and-pricing-card ${
                            plan.display_name === 'Standard' ? 'plans-and-pricing-card-popular' : ''
                        }`}
                        onClick={() => onPlanSelect && onPlanSelect(plan.tetherform_plan_ref)}
                    >
                        {plan.display_name === 'Standard' && (
                            <div className="plans-and-pricing-popular-tag">Most Popular</div>
                        )}
                        <div className="plans-and-pricing-card-header">
                            <h3>{plan.display_name}</h3>
                            <p className="plans-and-pricing-card-description">
                                {plan.plan_description}
                            </p>
                            <div className="plans-and-pricing-price">
                                <span className="plans-and-pricing-amount">
                                    {formatUSDCurrency(plan.monthly_price)}
                                </span>
                                <span className="plans-and-pricing-period">/ month</span>
                            </div>
                        </div>

                        <div className="plans-and-pricing-card-benefits">
                            <div className="plans-and-pricing-card-benefit">
                                <IoCheckmarkCircleSharp />
                                <span>Up to {plan.monthly_responses.toLocaleString()} responses/mo</span>
                            </div>
                            {plan.features.map((featureKey) => {
                                const feature = features.find(f => f.key === featureKey);
                                return feature && (
                                    <div key={featureKey} className="plans-and-pricing-card-benefit">
                                        <IoCheckmarkCircleSharp />
                                        <span>{feature.display_name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

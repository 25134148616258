import React from 'react'
import { FaList, FaStar, FaCheckSquare, FaFont, FaTimes, FaHeading } from 'react-icons/fa'
import { MdDragIndicator } from "react-icons/md"
import { Draggable } from 'react-beautiful-dnd'
import '../QuestionCanvas.css'

// Define supported element types and their configurations
const elementTypes = {
    'section-heading': {
        icon: FaHeading,
        label: 'SECTION HEADING',
        getDisplayText: (element) => element.content?.trim() || 'Section Heading',
        getDescription: (element) => element.description || '',
        className: 'question'
    },
    'multiple-choice': {
        icon: FaList,
        label: 'MULTIPLE CHOICE',
        getDisplayText: (element) => element.question?.trim() || 'Multiple Choice question',
        getDescription: (element) => element.description || '',
        className: 'question'
    },
    'rating': {
        icon: FaStar,
        label: 'RATING',
        getDisplayText: (element) => element.question?.trim() || 'Rating question',
        getDescription: (element) => element.description || '',
        className: 'question'
    },
    'select-multiple': {
        icon: FaCheckSquare,
        label: 'SELECT MULTIPLE',
        getDisplayText: (element) => element.question?.trim() || 'Select Multiple question',
        getDescription: (element) => element.description || '',
        className: 'question'
    },
    'text': {
        icon: FaFont,
        label: 'TEXT',
        getDisplayText: (element) => element.question?.trim() || 'Text question',
        getDescription: (element) => element.description || '',
        className: 'question'
    }
};

export default function FormCanvasItem({ element, index, onElementClick, onDeleteIconClick, onDuplicateIconClick, isNew }) {
    // Check if this is a supported element type
    const elementConfig = elementTypes[element.type];
    if (!elementConfig) {
        console.warn(`Unsupported form element type: ${element.type}`);
        return null;
    }

    const { icon: ElementIcon, label, getDisplayText, getDescription } = elementConfig;
    
    const handleEditClick = (e) => {
        e.stopPropagation();
        onElementClick(e, element);
    };

    return (
        <Draggable draggableId={element.id} index={index}>
            {(provided, snapshot) => (
                <div 
                    className={`question-canvas-question-item ${snapshot.isDragging ? 'is-dragging' : ''} ${isNew ? 'highlight-new' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <div 
                        className="question-canvas-drag-handle"
                        {...provided.dragHandleProps}
                    >
                        <MdDragIndicator />
                    </div>
                    <div className="question-canvas-question-content">
                        <div className="question-canvas-question-type">{label}</div>
                        <div className="question-canvas-question-text">{getDisplayText(element)}</div>
                        {getDescription(element) && (
                            <div className="question-canvas-question-description">{getDescription(element)}</div>
                        )}
                    </div>
                    <div 
                        className="question-canvas-delete-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteIconClick(e, element);
                        }}
                    >
                        <FaTimes />
                    </div>
                    <div className="question-canvas-question-footer">
                        <div className="question-canvas-question-icon">
                            <ElementIcon />
                        </div>
                        <div className="question-canvas-question-actions">
                            <button 
                                className="question-canvas-action-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDuplicateIconClick(e, element, index);
                                }}
                            >
                                Duplicate
                            </button>
                            <button 
                                className="question-canvas-action-button"
                                onClick={handleEditClick}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

// Export the element types configuration for use in other components
export { elementTypes }; 
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useOrganization } from '../../context/OrganizationContext';
import { IoDocumentText, IoSettings, IoPeople, IoLogOutOutline, IoChevronBackOutline } from 'react-icons/io5';
import './authenticated_sidebar.css';
import { IoMdAnalytics } from "react-icons/io";

const navigationItems = [
    {
        name: 'Forms',
        icon: IoDocumentText,
        path: 'forms'
    },
    {
        name: 'Analyze',
        icon: IoMdAnalytics,
        path: 'imports',
        is_new: true
    },
    {
        name: 'Settings',
        icon: IoSettings,
        path: 'settings'
    },
    {
        name: 'Users',
        icon: IoPeople,
        path: 'users'
    }
];

export default function AuthenticatedSidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();
    const { organization } = useOrganization();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [tooltipTimeout, setTooltipTimeout] = useState(null);

    const handleNavigation = (path) => {
        if (!organization?._id) return;
        const targetPath = `/o/${organization._id}/${path}`;
        navigate(targetPath);
    };

    const handleLogoClick = () => {
        if (organization?._id) {
            navigate(`/o/${organization._id}/forms`);
        } else {
            navigate('/');
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const isActivePath = (path) => {
        const currentPath = location.pathname.split('/');
        const targetPath = path.split('/');
        return currentPath[3] === targetPath[0];
    };

    const handleMouseEnter = (itemName) => {
        if (isCollapsed) {
            const timeout = setTimeout(() => {
                setHoveredItem(itemName);
            }, 500);
            setTooltipTimeout(timeout);
        }
    };

    const handleMouseLeave = () => {
        if (tooltipTimeout) {
            clearTimeout(tooltipTimeout);
        }
        setHoveredItem(null);
    };

    const getOrganizationInitial = () => {
        return organization?.name ? organization.name.charAt(0).toUpperCase() : 'O';
    };

    return (
        <div className={`auth-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="auth-sidebar-header">
                <div className="auth-sidebar-org">
                    <div className="auth-sidebar-org-initial">{getOrganizationInitial()}</div>
                    {!isCollapsed && (
                        <div className="auth-sidebar-org-name">
                            {organization?.name || 'Organization'}
                        </div>
                    )}
                </div>
            </div>

            <div 
                className="auth-sidebar-collapse-button" 
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <IoChevronBackOutline className={`auth-sidebar-collapse-icon ${isCollapsed ? 'reversed' : ''}`} />
            </div>

            <div className="auth-sidebar-nav">
                {navigationItems.map((item, index) => (
                    <div
                        key={index}
                        className={`auth-sidebar-nav-item ${isActivePath(item.path) ? 'active' : ''}`}
                        onClick={() => handleNavigation(item.path)}
                        onMouseEnter={() => handleMouseEnter(item.name)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <item.icon className="auth-sidebar-nav-icon" />
                        {!isCollapsed && <span>{item.name}</span>}
                        {isCollapsed && hoveredItem === item.name && (
                            <div className="auth-sidebar-tooltip">{item.name}</div>
                        )}
                        {item.is_new && <span className="auth-sidebar-new-item">Beta</span>}
                    </div>
                ))}
            </div>

            <div className="auth-sidebar-footer">
                <div 
                    className="auth-sidebar-logout" 
                    onClick={handleLogout}
                >
                    {isCollapsed ? (
                        <>
                            <IoLogOutOutline className="auth-sidebar-nav-icon" />
                            {hoveredItem === 'Logout' && (
                                <div className="auth-sidebar-tooltip">Log out</div>
                            )}
                        </>
                    ) : (
                        <span>Log out</span>
                    )}
                </div>
                <div className="auth-sidebar-logo" onClick={handleLogoClick} style={isCollapsed ? {display: "flex", justifyContent: "center", alignItems: "center"} : {}}>
                    {isCollapsed ? (
                        <img 
                            src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform-landing-public-assets/logo192.png" 
                            alt="Tetherform" 
                            className="auth-sidebar-logo-image" 
                        />
                    ) : (
                        <>Tether<span>form</span></>
                    )}
                </div>
            </div>
        </div>
    );
} 
import React, { useEffect } from 'react'
import './OrganizationUsers.css'
import { useOrganization } from '../../context/OrganizationContext'

export default function OrganizationUsers() {
    const { loading, error } = useOrganization()
    useEffect(() => {
        document.title = "Users | Tetherform";
    }, [])
    if (loading) {
        return <div>Loading details...</div>
    }
    if (error) {
        return <div>Something went wrong</div>
    }
    return (
        <div className="organization-users-container">
            <div className="organization-users-main">

                <h3>Invite teammates to collaborate on forms</h3>
                <p>Coming soon</p>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import './OrgSettings.css'
import { useNavigate } from 'react-router-dom'
import { getAccountManagementUrl } from '../../api'
import { useOrganization } from '../../context/OrganizationContext'
import { useAuth } from '../../context/AuthContext'
import OrgLogo from '../gizmos/OrgLogo'
import ModalOrgSettingsChangeLogo from '../modals/organization_settings/ModalOrgSettingsChangeLogo'
import { formatNumberAsString } from '../../helpers/Helpers'
import { IoCheckmark, IoCopy } from "react-icons/io5";

export default function OrgSettings() {
    const { organizationId, organization, loading, error, isActiveLicense } = useOrganization()
    const { user } = useAuth();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingLogo, setIsUpdatingLogo] = useState(false)
    const [managementError, setManagementError] = useState(null)
    const [showCopyFeedback, setShowCopyFeedback] = useState(false);

    const handleManagePlan = async () => {
        setIsLoading(true)
        setManagementError(null)
        try {
            const response = await getAccountManagementUrl(organizationId)
            if (response.data && response.data.checkoutSessionUrl) {
                window.location.href = response.data.checkoutSessionUrl
            } else {
                setManagementError('Unable to get management URL')
            }
        } catch (err) {
            setManagementError('An error occurred while fetching the management URL')
        } finally {
            setIsLoading(false)
        }
    }

    const handleCopyEmail = async () => {
        try {
            await navigator.clipboard.writeText('support@tetherform.com');
            setShowCopyFeedback(true);
            setTimeout(() => setShowCopyFeedback(false), 2000); // Reset after 2 seconds
        } catch (err) {
            console.error('Failed to copy email:', err);
        }
    };
    useEffect(() => {
        document.title = "Settings | Tetherform";
    }, [])

    if (loading || !organization || !user) {
        return <div>Loading organization details...</div>
    }
    if (error) {
        return <div>Error: {error}</div>
    }
    
    return (
        <div className="org-settings-container">
            {isUpdatingLogo && <ModalOrgSettingsChangeLogo close={() => setIsUpdatingLogo(false)} organization={organization} currentUrl={organization.organization_profile_picture_url} />}
            <div className="organization-settings-main">
                <h1>Settings</h1>
                <div className="organization-settings-sections">
                    <div className="organization-settings-section">
                        <h3>Basic Information</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Organization</div>
                            <div className="organization-settings-section-value">{organization.name}</div>
                        </div>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Primary Account Owner</div>
                            <div className="organization-settings-section-value">{user.email}</div>
                        </div>
                    </div>
                    {isActiveLicense &&
                    <div className="organization-settings-section">
                        <h3>Usage</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Total responses</div>
                            <div className="organization-settings-section-value">{formatNumberAsString(organization.total_responses)} / {formatNumberAsString(organization.monthly_responses)}</div>
                        </div>
                    </div>
                    }
                    <div className="organization-settings-section">
                        <h3>Account</h3>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Status</div>
                            <div className="organization-settings-section-value">{organization.subscription_status_pretty_string}</div>
                        </div>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">License</div>
                            <div style={{display: "flex", gap: "0.5rem"}}>
                                {organization.subscription_benefits && <div className="organization-settings-section-value">{organization.subscription_benefits.friendly_name}</div>}
                                {organization.subscription_status !== "none"  ? <div className="organization-settings-section-value">- {formatNumberAsString(organization.monthly_responses)} responses / mo</div> : <div className="organization-settings-section-value"></div>}
                            </div>
                        </div>
                        <div className="organization-setting-row">
                            <div className="organization-settings-section-key">Support</div>
                            <div 
                                className="organization-settings-copy-email" 
                                onClick={handleCopyEmail}
                                title="Click to copy email"
                            >
                                <div className="organization-settings-copy-email-content">
                                    <span>support@tetherform.com</span>
                                    {!showCopyFeedback && 
                                        <IoCopy className="organization-settings-copy-icon" />
                                    }
                                    {showCopyFeedback && 
                                        <IoCheckmark className="organization-settings-copy-icon copied" />
                                    }
                                </div>
                                <div className={`organization-settings-copy-tooltip ${showCopyFeedback ? 'show' : ''}`}>
                                    {showCopyFeedback ? 'Copied!' : 'Click to copy'}
                                </div>
                            </div>
                        </div>
                        {isActiveLicense ?
                        <div className="organization-settings-manage-plan" onClick={handleManagePlan} disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Update Subscription'}
                        </div>
                        : 
                        <div className="organization-settings-manage-plan" onClick={() => navigate(`/o/${organizationId}/rejoin`)} disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Sign Up'}
                        </div>
                        }
                    </div>
                    {isActiveLicense &&
                    <div className="organization-settings-section">
                        <h3>Branding</h3>
                        <div className="organization-setting-row" style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                            <div className="organization-settings-section-key">Logo</div>
                            <OrgLogo logoUrl={organization.organization_profile_picture_url} widthSizePixels={80} organizationName={organization.name} />
                        </div>
                        <div className="organization-settings-manage-logo" onClick={() => setIsUpdatingLogo(true)}>
                            Change logo
                        </div>
                    </div>
                    }
                </div>
                {managementError && <p className="error-message">{managementError}</p>}
                {!loading &&
                <>
                    {organization.monthly_responses <= organization.total_responses && isActiveLicense && <p className="error-message" style={{backgroundColor: "var(--color-extra-dark)", color: "var(--color-brand)"}}>You are out of responses for the month. Consider upgrading your plan!</p>}
                </>
                }
            </div>
        </div>
    )
}